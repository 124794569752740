import * as React from "react";
import { Table } from "tabler-react";
import editImg from "../../assets/Edit.png";
import deleteImg from "../../assets/Delete.png";

const obj = [
  "Name",
  "Entry Fee",
  "T1-T2",
  "T2-T3",
  "T3-T4",
  "Money Distribution",
  "Date Created",
  "Created By"
];

interface Props {
  data: any;
  edit: any;
  delete: any;
}

class BlockRows extends React.Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    return (
      <Table>
        <Table.Header>
          {obj.map((o, i) => (
            <Table.ColHeader key={i}>{o}</Table.ColHeader>
          ))}
        </Table.Header>
        <Table.Body>
          {data &&
            data.map((ob, i) => (
              <Table.Row key={i}>
                <Table.Col>{`${ob.Name} ${ob.Number}`}</Table.Col>
                <Table.Col>{`${ob.EntryFeeValue} ${
                  ob.EntryFeeCurrency
                } `}</Table.Col>
                <Table.Col>{ob.T1T2DurationMin}</Table.Col>
                <Table.Col>{ob.T2T3DurationMin}</Table.Col>

                <Table.Col>{ob.T3T4DurationMin}</Table.Col>
                <Table.Col>{`${ob.PercentageWinners.toFixed(
                  2
                )}% ${ob.Wdf.charAt(0) +
                  ob.Wdf.split("_")
                    .join(" ")
                    .toLowerCase()
                    .slice(1)}`}</Table.Col>
                <Table.Col>
                  {ob.CreatedAt
                    ? new Date(
                        Number(ob.CreatedAt.seconds) * 1000
                      ).toDateString()
                    : null}
                </Table.Col>
                <Table.Col>{ob.CreatedBy}</Table.Col>

                <Table.Col>
                  <img
                    src={editImg}
                    alt="edit-2"
                    onClick={() => this.props.edit(ob)}
                  />
                </Table.Col>
                <Table.Col>
                  <img
                    src={deleteImg}
                    alt="trash"
                    onClick={() => this.props.delete(ob.id)}
                  />
                </Table.Col>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    );
  }
}

export default BlockRows;
