import * as React from "react";
import BigCalendar from "react-big-calendar";
import * as moment from "moment";
// import "react-big-calendar/lib/css/react-big-calendar.css";
import dates from "date-arithmetic";

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = BigCalendar.momentLocalizer(moment); // or globalizeLocalizer

interface Props {
  rooms: Array<any>;
  gameName: string;
}

class RoomVisualizer extends React.Component<Props> {
  state = {
    startTime: new Date(new Date(new Date().setHours(0)).setMinutes(0)),
    endTime: new Date(new Date(new Date().setHours(23)).setMinutes(30)),
    step: 30,
    rooms: [
      {
        id: null,
        title: null,
        start: new Date(),
        t2: null,
        t3: null,
        end: null,
        gems: null,
        type: null,
        respawn: null,
        created_by: null,
        date: null,
        isSimulated: null
      }
    ],
    events: []
  };

  setStep = diff => {
    if (diff <= 360) {
      //6 hours
      this.setState({ step: 15 });
    }
    if (diff <= 120) {
      //2 hours
      this.setState({ step: 5 });
    }
  };

  handleStartTime = event => {
    // console.log(event.toDate());
    this.setState({ startTime: event.toDate() }, () => {
      const diff = dates.diff(
        this.state.startTime,
        this.state.endTime,
        "minutes"
      );
      //   console.log(diff);

      this.setStep(diff);
    });
  };

  getRooms = () => {
    // console.log(this.props.rooms, "in gertrooms");
    const rooms = this.props.rooms
      .filter(f => {
        if (f.Game) return f.Game.Name == this.props.gameName;
      })
      .map(room => {
        return {
          id: room && room.id,
          title: this.props.gameName,
          start:
            room.T1Timestamp && !room.IsSimulated
              ? new Date(Number(room.T1Timestamp.seconds) * 1000)
              : new Date(room.T1Timestamp),
          t2:
            room.T2Timestamp && !room.IsSimulated
              ? new Date(Number(room.T2Timestamp.seconds) * 1000)
              : new Date(room.T2Timestamp),
          t3:
            room.T3Timestamp && !room.IsSimulated
              ? new Date(Number(room.T3Timestamp.seconds) * 1000)
              : new Date(room.T3Timestamp),
          end:
            room.T4Timestamp && !room.IsSimulated
              ? new Date(Number(room.T4Timestamp.seconds) * 1000)
              : new Date(room.T4Timestamp),
          gems: room.Template && room.Template.Block.EntryFeeValue,
          type: room.Template && String(room.Template.Block.PercentageWinners),
          created_by: room.Template && room.Template.CreatedBy,
          date:
            room.Template && room.Template.CreatedAt && !room.IsSimulated
              ? new Date(Number(room.Template.CreatedAt.seconds) * 1000)
              : new Date(room.Template.CreatedAt),
          isSimulated: room.IsSimulated
        };
      });

    this.setState(
      { rooms }
      // () =>
      // console.log(this.state.rooms, "in didmount")
    );
  };

  componentDidMount = async () => {
    await this.setState(
      { rooms: this.props.rooms }
      //  () => console.log(this.state.rooms, "in didmount")
    );

    this.getRooms();

    // console.log(rooms);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.rooms != this.props.rooms) {
      this.getRooms();
      // this.setState({ rooms: this.props.rooms }, () =>
      //   console.log(this.state.rooms, "in didupdate")
      // );
    }
    if (prevProps.gameName != this.props.gameName) {
      this.getRooms();
    }
    if (prevState.rooms != this.state.rooms) {
      // console.log("didupdate state");
    }
  };

  handleEndTime = event => {
    // console.log(event.toDate());
    this.setState({ endTime: event.toDate() }, () => {
      const diff = dates.diff(
        this.state.startTime,
        this.state.endTime,
        "minutes"
      );
      //   console.log(this.state.startTime, diff);

      this.setStep(diff);
    });
  };

  public render() {
    // console.log(this.state.rooms, "inrender");
    return (
      <div className="room-vis">
        <BigCalendar
          popup
          colorType="green"
          step={this.state.step}
          timeslots={1}
          localizer={localizer}
          events={this.state.rooms}
          defaultView={BigCalendar.Views.WEEK}
          defaultDate={new Date()}
          views={{ day: true, week: true }}
          showMultiDayTimes
          startAccessor="start"
          endAccessor="end"
          tooltipAccessor={() => null}
        />
      </div>
    );
  }
}

export default RoomVisualizer;
