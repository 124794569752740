import * as types from "../constants/ActionTypes";

export const deleteBlocks = id => ({
  type: types.DELETE_BLOCKS,
  id
});

export const deleteRooms = id => ({
  type: types.DELETE_ROOM,
  id
});
