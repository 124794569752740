import * as types from "../constants/ActionTypes";

const initialState = {
  users: []
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_USERS:
      console.log(action.users);
      return {
        ...state,
        users: action.users
      };
    default:
      return state;
  }
};
