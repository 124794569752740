import * as React from "react";
import moment from "moment";
import listView from "../../assets/listview.png";
import DatePicker from "react-datepicker";

interface Props {
  handleStartTime: any;
  handleEndTime: any;
  startTime: any;
  endTime: any;
  history?: any;
}

class SchedulerToolbar extends React.Component<Props> {
  constructor(props) {
    super(props);
  }
  state = {
    // startTime: new Date(new Date(new Date().setHours(0)).setMinutes(0)),
    // endTime: moment(moment("23:59", "HH:mm")),
    filter: ""
  };

  render() {
    const { history } = this.props;
    return (
      <>
        <div className="toolbar-scheduler">
          <span className="rbc-toolbar-label">
            <label>Start Time</label>
            <DatePicker
              placeholderText="Select Date"
              selected={this.props.startTime}
              timeInputLabel="Time:"
              onChange={this.props.handleStartTime}
              dateFormat="h:mm aa"
              showTimeInput
              showTimeSelectOnly
            />
          </span>
          <span className="rbc-toolbar-label">
            <label>End Time</label>
            <DatePicker
              placeholderText="Select Date"
              selected={this.props.endTime}
              timeInputLabel="Time:"
              onChange={this.props.handleEndTime}
              dateFormat="h:mm aa"
              showTimeInput
              showTimeSelectOnly
            />
          </span>
          {/* <span className="rbc-toolbar-label">
            <label>Filter</label>
            <div>
              <Form.Select
                name="filter"
                // label="Choose Game"
                placeholder="Filter"
                // onChange={handleChange}
                // onBlur={handleBlur}
                value={this.state.filter}
              >
                <option value="3">Today</option>
                <option value="4">lorem</option>
              </Form.Select>
            </div>
          </span> */}
          <span className="rbc-toolbar-label">
            <img
              src={listView}
              alt="list"
              onClick={() => history.push("/room/list")}
            />
          </span>
        </div>
      </>
    );
  }
}

export default SchedulerToolbar;
