import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import graphReducer from "./graphReducer";
import loginReducer from "./loginReducer";
import formReducer from "./formReducer";

export default (history: any) =>
  combineReducers({
    router: connectRouter(history),
    graphState: graphReducer,
    loginState: loginReducer,
    blockState: formReducer
  });
