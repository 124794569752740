import * as React from "react";
import { Dialog } from "@blueprintjs/core";
import { Table, Icon, Grid, Button, Form } from "tabler-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Formik } from "formik";
import { AuthConsumer } from "../../Contexts/AuthContext";

interface DialogState {
  autoFocus: boolean;
  canEscapeKeyClose: boolean;
  canOutsideClickClose: boolean;
  enforceFocus: boolean;
  isOpen: boolean;
  usePortal: boolean;
}

interface DialogProps {
  isOpen: boolean;
  handleClose(): void;
  handleStartDate: any;
  handleEndDate: any;
  startDate: any;
  endDate: any;
  handleSubmit: any;
  handleCancel: any;
  pauseIntervals: any;
  token: any;
  error?: any;
}

export const pauseStates = [
  {
    label: "Cancelled",
    value: "-1"
  },
  {
    label: "Aborted",
    value: "0"
  },
  {
    label: "Completed",
    value: "1"
  },
  {
    label: "Active",
    value: "2"
  },
  {
    label: "Scheduled",
    value: "3"
  }
];

class PausePlayDialog extends React.Component<DialogProps, DialogState> {
  public state: DialogState = {
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    isOpen: false,
    usePortal: true
  };

  render() {
    return (
      <Dialog
        className="pause-dialog"
        icon="info-sign"
        onClose={this.props.handleClose}
        title=""
        {...this.state}
        isOpen={this.props.isOpen}
      >
        <Table>
          <Table.Header>
            {/* <Table.ColHeader>Room Name</Table.ColHeader> */}
            <Table.ColHeader>Start Time</Table.ColHeader>
            <Table.ColHeader>End Time</Table.ColHeader>

            <Table.ColHeader>State</Table.ColHeader>
          </Table.Header>
          <Table.Body>
            {this.props.pauseIntervals &&
              this.props.pauseIntervals.map((o, i) => (
                <>
                  {
                    <Table.Row key={i}>
                      {/* <Table.Col>{o.Name}</Table.Col> */}
                      <Table.Col>
                        {" "}
                        {moment
                          .unix(o.StartTime && o.StartTime.seconds)
                          .format("MMM DD h:mm A")}
                      </Table.Col>
                      <Table.Col>
                        {moment
                          .unix(o.EndTime && o.EndTime.seconds)
                          .format("MMM DD h:mm A")}
                      </Table.Col>

                      <Table.Col>
                        {pauseStates.map((s, i) =>
                          s.value == o.State ? <p>{s.label}</p> : null
                        )}
                      </Table.Col>
                      <Table.Col>
                        <Icon
                          name="x-circle"
                          onClick={() =>
                            this.props.handleCancel(o.id, this.props.token)
                          }
                        />
                      </Table.Col>
                    </Table.Row>
                  }
                </>
              ))}
          </Table.Body>
        </Table>
        <AuthConsumer>
          {({ idToken }: any) => (
            <Formik
              enableReinitialize
              initialValues={{
                startTime: null,
                endTime: null
              }}
              // validationSchema={RoomSchema}
              onSubmit={(values, actions) => {
                this.props.handleSubmit(values, idToken);
                setTimeout(() => {
                  actions.setSubmitting(false);
                  actions.resetForm();
                }, 400);
              }}
            >
              {({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid.Row>
                    <Grid.Col md={3} offset={3}>
                      <label>Start Time</label>
                      <DatePicker
                        placeholderText="Select Date"
                        selected={this.props.startDate}
                        timeInputLabel="Time:"
                        onChange={this.props.handleStartDate}
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeInput
                      />
                    </Grid.Col>
                    <Grid.Col md={3}>
                      <label>End Time</label>
                      <DatePicker
                        placeholderText="Select Date"
                        selected={this.props.endDate}
                        timeInputLabel="Time:"
                        onChange={this.props.handleEndDate}
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeInput
                      />
                    </Grid.Col>
                  </Grid.Row>
                  <Button
                    className="submit"
                    type="submit"
                    //   disabled={isSubmitting}
                  >
                    Save
                  </Button>
                  {this.props.error &&
                    this.props.error !== "protobuf_Empty is not defined" && (
                      <p style={{ color: "red" }}>{this.props.error}</p>
                    )}
                  {this.props.error == "protobuf_Empty is not defined" && (
                    <p style={{ color: "green" }}>{"Success"}</p>
                  )}
                </Form>
              )}
            </Formik>
          )}
        </AuthConsumer>
      </Dialog>
    );
  }
}

export default PausePlayDialog;
