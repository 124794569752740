import {
  cancel,
  cancelled,
  put,
  takeEvery,
  takeLatest,
  delay,
  all,
  call,
  take,
  fork
} from "redux-saga/effects";
import { rsf } from "../firestore";
import * as types from "../constants/ActionTypes";

export function* getOptions() {
  // while (true) {
  // yield take(types.GET_CREATE_BLOCK_OPTIONS);
  const task = yield fork(fetchCreateOptions);
  yield delay(1000);
}

export function* fetchCreateOptions() {
  const snapshot = yield call(
    rsf.firestore.getDocument,
    "houses/mega/options/Block"
  );
  const options = snapshot.data().CreateOptions;
  // console.log(snapshot.data().CreateOptions);

  yield put({ type: types.GET_CREATE_BLOCK_OPTIONS, options });
}

// export function* fetchBlockOptions() {
//   yield call(getOptions);
// }

export function* getBlocks() {
  const snapshot = yield call(
    rsf.firestore.getCollection,
    "houses/mega/blocks"
  );
  let list;
  snapshot.forEach(function(e) {
    list = {
      ...list,
      [e.id]: {
        ...e.data(),
        id: e.id
      }
    };
  });
  // console.log(list);
  yield put({ type: types.GET_BLOCKS, list: Object.values(list) });
}

export default function* blocks() {
  yield takeEvery(types.REQUEST_CREATE_BLOCK_OPTIONS, fetchCreateOptions),
    yield takeEvery(types.REQUEST_BLOCKS, getBlocks);
}
