import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthConsumer } from "../Contexts/AuthContext";

const ProtectedRoute = ({ component, ...rest }: any) => (
  <AuthConsumer>
    {({ isAuth }: any) => {
      const Component = component;
      return (
        <Route
          render={props =>
            isAuth ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{ pathname: "/", state: { from: props.location } }}
              />
            )
          }
          {...rest}
        />
      );
    }}
  </AuthConsumer>
);

export default ProtectedRoute;
