import * as types from "../constants/ActionTypes";

const initialState = {
  loading: false,
  isAuth: false,
  user: null,
  username: null,
  message: null,
  redirectToReferrer: false,
  idToken: null
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
    case types.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
        message: null
      };

    case types.LOGIN_SUCCESS:
      localStorage.setItem("loading", "true");
      return {
        ...state,
        idToken: action.data.credential.idToken,
        loading: false,
        isAuth: true,
        redirectToReferrer: true
      };

    case types.LOGIN_ERROR:
    case types.LOGOUT_ERROR:
      localStorage.removeItem("loading");
      return {
        ...state,
        idToken: null,
        loading: false,
        message: action.error.message
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuth: false,
        redirectToReferrer: false
      };
    case types.SYNC_USER:
      // console.log(action.user);
      return {
        ...state,
        idToken: action.idToken,
        isAuth: action.user != null,
        user: action.user,
        username: action.user && action.user.displayName,
        redirectToReferrer: action.user != null
      };
    case types.CHECK_USER:
      const domain =
        action.user != null ? action.user.email.split("@")[1] : null;
      if (domain == "getmega.com") {
        return {
          ...state,
          isAuth: action.user != null,
          user: action.user,
          email: action.user.email,
          username: action.user && action.user.displayName,
          redirectToReferrer: action.user != null
        };
      } else {
        localStorage.removeItem("loading");
        return {
          ...state,
          isAuth: false,
          idToken: null,
          user: action.user,
          username: null,
          email: action.user.email,
          redirectToReferrer: false
        };
      }

    default:
      return state;
  }
};
