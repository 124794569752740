import * as React from "react";
import { Formik, FieldArray } from "formik";
import { Header as H, Form, Button } from "tabler-react";
import * as fetch from "isomorphic-fetch";
import { TwirpError } from "../../rpc/service/twirp";
// import { TimePicker, TimePrecision } from "@blueprintjs/datetime";
import { Checkbox, Alignment } from "@blueprintjs/core";
import Home from "../../components/home";
import Yup from "../../constants/validation";
// import Input from "react-moment-input";
import moment from "moment";
import { AuthConsumer } from "../../Contexts/AuthContext";
import { ScheduleService } from "../../rpc/service/pb";
import { connect } from "react-redux";

export const days = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY"
];

export const slots = [
  {
    label: "Early Morning(6-9)",
    value: "EARLY_MORNING"
  },
  {
    label: "Morning(9-12)",
    value: "MORNING"
  },
  {
    label: "Afternoon(12-3)",
    value: "AFTERNOON"
  },
  {
    label: "Late Afternoon(3-6)",
    value: "LATE_AFTERNOON"
  },
  {
    label: "Evening(6-9)",
    value: "EVENING"
  },
  {
    label: "Night(9-12)",
    value: "NIGHT"
  },
  {
    label: "Late Night(12-3)",
    value: "LATE_NIGHT"
  },
  {
    label: "Sleep Time(3-6)",
    value: "SLEEP_TIME"
  }
];
const ScheduleSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  number: Yup.number().required("Required"),
  days: Yup.string().required("Required"),
  spawn_windows: Yup.string().required("Required")
});
// console.log(slots);
interface Props {
  user: any;
  history: any;
}
class CreateSchedule extends React.Component<Props> {
  state = {
    error: null,
    offset: moment(moment("00:00", "HH:mm"))
  };

  private handleSubmit(values, token) {
    const block = new ScheduleService(process.env.REACT_APP_FETCH_URL, fetch);
    values = {
      ...values,
      created_by: this.props.user.displayName
    };
    block
      .createSchedule(values, { authorization: `bearer ${token}` })
      .then(() => {
        this.props.history.push("/schedule/list");
      })
      .catch((err: TwirpError) => {
        console.error(err.code + ": " + err.message);
        this.setState({ error: err.message });
      });
  }

  handleStartTime = event => {
    console.log(event);
    this.setState({ startTime: event }, () => {
      console.log(event.toDate());
      //   this.setStep(diff);
    });
  };
  public render() {
    return (
      <AuthConsumer>
        {({ idToken }: any) => (
          <div className="template-form">
            <Home>
              <H.H1>Create a Schedule</H.H1>
              <hr />
              <Formik
                initialValues={{
                  name: "",
                  days: [],
                  spawn_windows: [],
                  number: ""
                }}
                validationSchema={ScheduleSchema}
                onSubmit={(values, actions) => {
                  this.handleSubmit(values, idToken);
                  setTimeout(() => {
                    // alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                    actions.resetForm();
                  }, 400);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Input
                      name="name"
                      label="Name of the Schedule"
                      placeholder="write name here"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name ? (
                      <div className="error">{errors.name}</div>
                    ) : null}
                    <Form.Input
                      name="number"
                      label="Number of the Schedule"
                      placeholder="Number of the Schedule"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.number}
                    />
                    {errors.number && touched.number ? (
                      <div className="error">{errors.number}</div>
                    ) : null}
                    {/* <Form.Select
                  name="block"
                  label="Choose Block"
                  placeholder="Choose Block"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.block}
                >
                  <option value="BL1">BL 1</option>
                  <option value="BL2">BL 2</option>
                </Form.Select> */}
                    {/* <div className="block-detail">
                  <Grid.Row>
                    <Grid.Col>
                      <Text>Entry</Text>
                      <Text>{`${obj[`${values.block}`]["entry"]} Gems`}</Text>
                    </Grid.Col>
                    <Grid.Col>
                      <Text>Duration</Text>
                      <Text>3 Gems</Text>
                    </Grid.Col>
                    <Grid.Col>
                      <Text>Distribution</Text>
                      <Text>3 Gems</Text>
                    </Grid.Col>
                  </Grid.Row>
                </div> */}
                    {/* <div className="form-group">
                  <Form.Label>Offset</Form.Label>
                  <Input
                    value={this.state.offset}
                    readOnly={false}
                    options={false}
                    tab={1}
                    format="HH:mm"
                    onChange={this.handleStartTime}
                  />
                </div> */}
                    <Form.Label>Repeat On</Form.Label>
                    <FieldArray
                      name="days"
                      render={arrayHelpers => (
                        <div className="days">
                          {days.map(day => (
                            <Checkbox
                              name="days"
                              key={day}
                              alignIndicator={Alignment.LEFT}
                              disabled={false}
                              inline={true}
                              large={false}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                if (e.target.checked) arrayHelpers.push(day);
                                else {
                                  const idx = values.days.indexOf(day);
                                  arrayHelpers.remove(idx);
                                }
                              }}
                              checked={values.days.includes(day)}
                              value={day}
                            />
                          ))}
                        </div>
                      )}
                    />
                    {errors.days && touched.days ? (
                      <div className="error">{errors.days}</div>
                    ) : null}

                    <div className="slots">
                      <Form.Label>Respawn At</Form.Label>
                      <FieldArray
                        name="spawn_windows"
                        render={arrayHelpers => (
                          <>
                            {slots.map(el => (
                              <Checkbox
                                name="spawn_windows"
                                key={el.value}
                                alignIndicator={Alignment.LEFT}
                                disabled={false}
                                inline={true}
                                large={false}
                                label={el.label}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  if (e.target.checked)
                                    arrayHelpers.push(el.value);
                                  else {
                                    const idx = values.spawn_windows.indexOf(
                                      el.value
                                    );
                                    arrayHelpers.remove(idx);
                                  }
                                }}
                                checked={values.spawn_windows.includes(
                                  el.value
                                )}
                                value={el.value}
                              />
                            ))}
                          </>
                        )}
                      />
                    </div>
                    {errors.spawn_windows && touched.spawn_windows ? (
                      <div className="error">{errors.spawn_windows}</div>
                    ) : null}
                    <br />
                    <Button type="cancel" disabled={isSubmitting}>
                      Cancel
                    </Button>
                    <Button
                      className="submit"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Create
                    </Button>
                    <p className="twirp-error">{this.state.error}</p>
                  </Form>
                )}
              </Formik>
            </Home>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    router: state.router,
    user: state.loginState.user
  };
};

export default connect(mapStateToProps)(CreateSchedule);
