import * as React from "react";
import { Grid, Header as H, Nav, AccountDropdown, Icon } from "tabler-react";
import { SubMenu, Item as MenuItem, Divider } from "rc-menu";
import { withRouter } from "react-router-dom";
import { AuthConsumer } from "../Contexts/AuthContext";
import { Link } from "react-router-dom";
import DrawerLeft from "./DrawerLeft";
import handle from "../assets/browse.png";
import CommonMenu from "./Menu";

const nav = [
  { text: "Blocks", viewLink: "/blocks/list", createLink: "/block/create" },
  {
    text: "Schedule",
    viewLink: "/schedule/list",
    createLink: "/schedule/create"
  },
  {
    text: "Set of Rooms",
    viewLink: "/room/list",
    createLink: "/room/create"
  }
];
interface Props {
  location: any;
}
interface State {
  left: boolean;
}

class Header extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      left: false
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  close = () => {
    this.setState({ left: false });
  };

  toggleDrawer = open => () => {
    this.setState(
      {
        left: open
      }
      // () => console.log(this.state)
    );
  };
  render() {
    // console.log(this.props, "header");
    return (
      <AuthConsumer>
        {({ isAuth, logout, user }: any) => (
          <>
            <div className="nav">
              <Grid.Row>
                <Grid.Col md={12}>
                  <Nav>
                    <Grid.Col md={5}>
                      <img
                        className="handle-nav"
                        src={handle}
                        width="4%"
                        onClick={this.toggleDrawer(true)}
                      />
                    </Grid.Col>
                    <Grid.Col md={5} className="title">
                      <Link to="/dashboard">
                        <H.H4>MEGA</H.H4>
                      </Link>
                    </Grid.Col>
                    {/* <Grid.Col md={1}>
                      <Notification.Tray unread={true} />
                    </Grid.Col> */}
                    <Grid.Col md={1}>
                      <AccountDropdown
                        avatarURL="./demo/faces/female/25.jpg"
                        options={[
                          // "profile",
                          // {
                          //   icon: "settings",
                          //   value: "Settings",
                          //   to: "/settings"
                          // },
                          // "mail",
                          // "message",
                          // "divider",
                          [user],
                          {
                            to: "#",
                            value: [user]
                          },
                          "logout",
                          {
                            to: "/logout"
                          }
                        ]}
                      />
                    </Grid.Col>
                  </Nav>
                </Grid.Col>
              </Grid.Row>
            </div>
            <DrawerLeft
              width="370px"
              getContainer={null}
              placement="left"
              open={this.state.left}
              handler={null}
              onMaskClick={this.toggleDrawer(false)}
            >
              <Grid.Col className="routes">
                {/* {nav.map((e, i) => (
                  <React.Fragment key={i}>
                    <Link to={e.link} onClick={this.close}>
                      <H.H3>{e.text}</H.H3>
                      <Icon name="chevron-right" />
                    </Link>
                    <hr />
                  </React.Fragment>
                ))} */}
                <CommonMenu mode="inline">
                  <MenuItem>
                    <Link to="/dashboard" onClick={this.close}>
                      View Scheduler
                      <Icon name="chevron-right" />
                    </Link>
                  </MenuItem>
                  {/* <Divider /> */}
                  {nav.map((e, i) => (
                    <SubMenu
                      title={
                        <>
                          <span className="submenu-title-wrapper">
                            {e.text}
                          </span>
                          <Icon name="chevron-right" />
                        </>
                      }
                      key={i}
                    >
                      <MenuItem>
                        <Link
                          to={{
                            pathname: `${e.viewLink}`,
                            state: { from: this.props.location.pathname }
                          }}
                          onClick={this.close}
                        >
                          View
                        </Link>
                      </MenuItem>

                      <MenuItem>
                        <Link
                          to={{
                            pathname: `${e.createLink}`,
                            state: { from: this.props.location.pathname }
                          }}
                          onClick={this.close}
                        >
                          Create
                        </Link>
                      </MenuItem>
                    </SubMenu>
                  ))}
                  {/* <MenuItem>
                    <Link to="/dashboard" onClick={this.close}>
                      Games
                      <Icon name="chevron-right" />
                    </Link>
                  </MenuItem> */}
                  {/* <Divider /> */}
                  {/* <MenuItem>
                    <Link to="/dashboard" onClick={this.close}>
                      Reports
                      <Icon name="chevron-right" />
                    </Link>
                  </MenuItem> */}
                </CommonMenu>
              </Grid.Col>
            </DrawerLeft>
          </>
        )}
      </AuthConsumer>
    );
  }
}

export default withRouter(Header);
