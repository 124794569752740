import { takeEvery, call } from "redux-saga/effects";
import { rsf } from "../firestore";
import * as types from "../constants/ActionTypes";

export function* deleteBlock(data) {
  console.log(data.id, "id");
  yield call(rsf.firestore.deleteDocument, "/houses/mega/blocks/" + data.id);
}

export function* deleteRoom(data) {
  console.log(data.id, "id");
  yield call(rsf.firestore.deleteDocument, "/houses/mega/rooms/" + data.id);
}

export default function* deleteSaga() {
  yield takeEvery(types.DELETE_BLOCKS, deleteBlock);
  yield takeEvery(types.DELETE_ROOM, deleteRoom);
}
