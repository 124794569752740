import * as React from "react";
import {
  ComposedChart,
  Area,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import { connect } from "react-redux";

interface Props {
  users: Array<any>;
}

class Graph extends React.Component<Props> {
  public render() {
    return (
      <ComposedChart
        width={800}
        height={600}
        data={this.props.users}
        margin={{ top: 100, right: 20, bottom: 20, left: 20 }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Area type="monotone" dataKey="rooms" fill="#8884d8" stroke="#8884d8" />
        <Bar dataKey="sessions" barSize={20} fill="#413ea0" />
        <Line type="monotone" dataKey="time" stroke="#ff7300" />
      </ComposedChart>
    );
  }
}

const mapStateToProps = (state: any) => ({
  users: state.graphState.users
});

export default connect(mapStateToProps)(Graph);
