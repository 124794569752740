import * as types from "../constants/ActionTypes";

export const requestCreateBlockOptions = () => ({
  type: types.REQUEST_CREATE_BLOCK_OPTIONS
});

export const getCreateBlockOptions = (options = []) => ({
  type: types.GET_CREATE_BLOCK_OPTIONS,
  options
});

export const requestBlocks = () => ({
  type: types.REQUEST_BLOCKS
});

export const getBlocks = (list = []) => ({
  type: types.GET_BLOCKS,
  list
});

export const requestGames = () => ({
  type: types.REQUEST_GAMES
});

export const getGames = (games = []) => ({
  type: types.GET_GAMES,
  games
});

export const requestSchedule = () => ({
  type: types.REQUEST_SCHEDULE
});

export const getSchedule = (scheduleList = []) => ({
  type: types.GET_SCHEDULE,
  scheduleList
});

export const requestRooms = () => ({
  type: types.REQUEST_ROOMS
});

export const getRooms = (rooms = []) => ({
  type: types.GET_ROOMS,
  rooms
});

export const requestTemplates = () => ({
  type: types.REQUEST_TEMPLATES
});

export const getTemplates = (
  templates = [],
  pausedTemplates = [],
  completedTemplates = []
) => ({
  type: types.GET_TEMPLATES,
  templates,
  pausedTemplates,
  completedTemplates
});

export const requestPauseIntervals = id => ({
  type: types.REQUEST_PAUSE_INTERVALS,
  id
});

export const getPauseIntervals = (pauseIntervals = []) => ({
  type: types.GET_PAUSE_INTERVALS,
  pauseIntervals
});

export const requestTags = () => ({
  type: types.REQUEST_TAGS
});

export const getTags = (tags = []) => ({
  type: types.GET_TAGS,
  tags
});

export const requestWdc = () => ({
  type: types.REQUEST_WDC
});

export const getWdc = (wdc = []) => ({
  type: types.GET_WDC,
  wdc
});
