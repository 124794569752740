import * as React from "react";
import { AuthConsumer } from "../Contexts/AuthContext";
import { Redirect } from "react-router-dom";
import { Button, Dimmer, Grid } from "tabler-react";

interface Props {
  location: any;
}

class Login extends React.Component<Props> {
  public render() {
    const { from } = this.props.location.state || {
      from: { pathname: "/dashboard" }
    };
    return (
      <AuthConsumer>
        {({ isAuth, login, redirectToReferrer, loading }: any) => {
          if (isAuth) {
            if (redirectToReferrer) {
              return <Redirect to={from} />;
            }
          } else {
            if (loading) {
              return (
                <Grid.Row>
                  <Grid.Col>
                    <Dimmer active loader />
                  </Grid.Col>
                </Grid.Row>
              );
            } else {
              return (
                <Dimmer active={loading} loader>
                  <Button onClick={login} social="google" pill>
                    Sign In
                  </Button>
                </Dimmer>
              );
            }
          }
        }}
      </AuthConsumer>
    );
  }
}

export default Login;
