import * as React from "react";
import Menu from "rc-menu";
import "rc-menu/assets/index.css";

interface Props {
  mode: String;
}

class CommonMenu extends React.Component<Props> {
  render() {
    return <Menu mode={this.props.mode}>{this.props.children}</Menu>;
  }
}

export default CommonMenu;
