import * as React from "react";
import { Table } from "tabler-react";
import { Checkbox, Alignment } from "@blueprintjs/core";
import editImg from "../../assets/Edit.png";
import deleteImg from "../../assets/Delete.png";

const obj = [
  "Name",

  "Repeat Cycle",
  "Respawn Time",
  "Created At",
  "Created By"
];

const days = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY"
];

interface Props {
  data: any;
}

class TemplateRows extends React.Component<Props, {}> {
  constructor(props) {
    super(props);
  }
  render() {
    const { data } = this.props;
    return (
      <>
        <Table>
          <Table.Header>
            {obj.map((o, i) => (
              <Table.ColHeader key={i}>{o}</Table.ColHeader>
            ))}
          </Table.Header>
          <Table.Body>
            {data.map((ob, i) => (
              <Table.Row key={i}>
                <Table.Col>{ob.Name}</Table.Col>

                <Table.Col>
                  <div className="days">
                    {days.map((m, i) => (
                      <Checkbox
                        key={i}
                        alignIndicator={Alignment.LEFT}
                        disabled
                        inline={true}
                        large={false}
                        checked={ob.Days.includes(m)}
                      />
                    ))}
                  </div>
                </Table.Col>
                <Table.Col className="respawn-row">
                  {ob.SpawnWindows.map((m, i) => (
                    <p key={i}>{m + ", "}</p>
                  ))}
                </Table.Col>
                <Table.Col>
                  {new Date(Number(ob.CreatedAt.seconds) * 1000).toDateString()}
                </Table.Col>
                <Table.Col>{ob.CreatedBy}</Table.Col>
                <Table.Col>
                  <img src={editImg} alt="edit-2" onClick={() => null} />
                </Table.Col>
                <Table.Col>
                  <img src={deleteImg} alt="trash" onClick={() => null} />
                </Table.Col>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    );
  }
}

export default TemplateRows;
