import * as React from "react";
import { Header as H, Icon, Form } from "tabler-react";
import Home from "../../components/home";
import moment from "moment";
import dates from "date-arithmetic";
import Select from "react-select";
import { bindActionCreators } from "redux";
import * as fetch from "isomorphic-fetch";
import {
  requestRooms,
  requestTemplates,
  requestGames
} from "../../actions/formActions";
import { TwirpError } from "../../rpc/service/twirp";
import Pagination from "../../components/pagination";
// import leftNav from "../../assets/nav1.png";
// import rightNav from "../../assets/nav2.png";
// import calendarIcon from "../../assets/tileview.png";
import ScheduleBox from "./scheduleBox";
import { connect } from "react-redux";
import { slots } from "../Template/createSchedule";
import { TemplateService } from "../../rpc/service/pb";

export const days = [
  {
    label: "Monday",
    value: "MONDAY"
  },
  {
    label: "Tuesday",
    value: "TUESDAY"
  },
  {
    label: "Wednesday",
    value: "WEDNESDAY"
  },
  {
    label: "Thursday",
    value: "THURSDAY"
  },
  {
    label: "Friday",
    value: "FRIDAY"
  },
  {
    label: "Saturday",
    value: "SATURDAY"
  },
  {
    label: "Sunday",
    value: "SUNDAY"
  }
];

interface Props {
  rooms: any;
  router: any;
  requestRooms: any;
  requestTemplates: any;
  requestPauseIntervals: any;
  requestGames: any;
  games: any;
  templates: any;
  pausedTemplates: any;
  completedTemplates: any;
  history: any;
}

class SchedulerList extends React.Component<Props> {
  state = {
    label: moment().format("ddd,LL"),
    Filters: {
      duration: "all",
      game: "all",
      repeat: "all",
      timeslots: "all",
      status: "Active"
    },
    search: "",
    newTemp: [],
    rooms: [],
    allTemplates: [],
    currentRooms: [],
    currentTemplates: [],
    templates: [],
    allRooms: [],
    initialData: [],
    variable: "all"
  };

  private handleArchive = async (id, token) => {
    id = { template_id: id };
    if (process.env.REACT_APP_ENV == "DEV") {
      token = "dev";
    }
    const archive = new TemplateService(process.env.REACT_APP_FETCH_URL, fetch);
    await archive
      .archiveTemplate(id, { authorization: `bearer ${token}` })
      .then(res => {
        // this.props.history.push("/room/list");
        console.log(res, "res");
      })
      .catch((err: TwirpError) => {
        console.error(err.code + ": " + err.message);
        this.setState({ error: err.message });
      });

    await this.props.requestTemplates();
  };

  fetchData = async () => {
    await this.props.requestRooms();
    await this.props.requestTemplates();
    await this.props.requestGames();
  };

  componentDidMount = async () => {
    await this.fetchData();
  };

  onPageChanged = async data => {
    // console.log(data, "-----data-------");
    const { templates } = await this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentTemplates = templates.slice(offset, offset + pageLimit);

    this.setState(
      { currentPage, totalPages, currentTemplates }
      // , () =>
      // console.log(this.state.currentRooms, "curntroom")
    );
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.rooms != this.props.rooms) {
      this.setState(
        { allRooms: this.props.rooms }
        // , () =>
        // console.log(this.state.rooms, "rooms in did update")
      );
    }
    if (prevProps.templates != this.props.templates) {
      // console.log("called");
      let allTemplates = await this.props.templates.filter(
        f => f.Archived == false
      );
      let pausedTemplates = (await this.props.pausedTemplates)
        ? this.props.pausedTemplates
        : [];
      let completedTemplates = (await this.props.completedTemplates)
        ? this.props.completedTemplates
        : [];
      // console.log(allTemplates, "alltemp");
      let templates = await allTemplates;
      // let templates = allTemplates.filter(
      //   template =>
      //     moment.unix(template.CreatedAt.seconds).format("ddd,LL") ==
      //     this.state.label
      // );
      await this.setState(
        {
          allTemplates,
          templates,
          newTemp: [...templates, ...pausedTemplates, ...completedTemplates]
        }
        // ,
        // () => console.log(this.state.newTemp, "newTemplates in did update")
      );
      let data = {
        currentPage: 1,
        pageLimit: 16,
        totalPages: Math.ceil(this.state.templates.length / 16),
        totalRecords: this.state.templates.length
      };
      // console.log("data=====", data);
      this.onPageChanged(data);
      // this.applyFilter(this.state.Filters);
    }
    // if (prevState.label != this.state.label) {
    //   let templates = this.state.allTemplates.filter(
    //     template =>
    //       moment
    //         .unix(template && template.CreatedAt.seconds)
    //         .format("ddd,LL") == this.state.label
    //   );
    //   await this.setState(
    //     { templates, newTemp: templates }
    //     // , () =>
    //     // console.log(this.state.templates, "templates in did update")
    //   );
    //   let data = {
    //     currentPage: 1,
    //     pageLimit: 16,
    //     totalPages: Math.ceil(this.state.templates.length / 16),
    //     totalRecords: this.state.templates.length
    //   };
    //   // console.log("data=====", data);
    //   this.onPageChanged(data);
    //   this.applyFilter(this.state.Filters);
    // }
  };

  handleChange = event => {
    let Filters = { ...this.state.Filters };
    let name = event.target.name;
    Filters[event.target.name] = event.target.value;
    this.setState({ ...this.state.Filters, Filters }, () =>
      this.applyFilter(this.state.Filters)
    );
  };

  handleSelect = (data, name) => {
    // console.log(data, name, "data name");
    this.setState(
      {
        Filters: { ...this.state.Filters, [name]: data }
      },
      () => {
        // console.log(this.state.Filters);
        this.applyFilter(this.state.Filters);
      }
    );
  };

  durationFilter = (duration, newTemp) => {
    // const { newTemp } = this.state;
    // console.log(newTemp, "newtemp in duration");
    let temp;

    switch (duration) {
      case "120":
        temp = newTemp.filter(e => {
          return e.Block.DurationMin < duration;
        });
        break;
      case "240":
        temp = newTemp.filter(e => {
          return e.Block.DurationMin < duration && e.Block.DurationMin >= 120;
        });
        break;
      case "360":
        temp = newTemp.filter(e => {
          return e.Block.DurationMin < duration && e.Block.DurationMin >= 240;
        });
        break;
      case "720":
        temp = newTemp.filter(e => {
          return e.Block.DurationMin < duration && e.Block.DurationMin >= 360;
        });
        break;
      case "1440":
        temp = newTemp.filter(e => {
          return e.Block.DurationMin < duration && e.Block.DurationMin >= 720;
        });
        break;
      default:
        temp = newTemp;
    }

    this.setTemplates(temp);

    return temp;
  };

  setTemplates = temp => {
    this.setState(
      {
        templates: temp
      },
      () => {
        let data = {
          currentPage: 1,
          pageLimit: 16,
          totalPages: Math.ceil(this.state.templates.length / 16),
          totalRecords: this.state.templates.length
        };
        this.onPageChanged(data);

        // console.log(this.state.templates);
      }
    );
  };

  gameFilter = (g, newTemp) => {
    // const { newTemp } = this.state;
    // console.log(newTemp, "newtemp in game", g);

    let temp =
      g !== "all" && g !== undefined
        ? newTemp.filter(f => {
            return f.GameName == g;
          })
        : newTemp;
    // console.log("temp in game", temp);
    this.setTemplates(temp);
    return temp;
  };

  timeslotFilter = async (g, newTemp) => {
    // console.log(Array.isArray(g), "timeslots  in tsfilter", g);
    if (Array.isArray(g)) {
      let temp = g.map(g =>
        g.value !== "all" && g.value !== undefined
          ? newTemp.filter(a => a.Schedule.SpawnWindows.indexOf(g.value) > -1)
          : newTemp
      );
      let i, temp1;
      temp1 = g.length && [...temp[0]];
      if (g.length > 1) {
        for (i = 1; i < g.length; i++) {
          // console.log([...temp[i], ...temp[0]], "timesl");
          temp1 = await [...temp[i], ...temp1];
        }
      }
      temp1 = g.length ? [...new Set(temp1)] : newTemp;
      // console.log("temp in timeslot", temp);
      await this.setTemplates(temp1);
      return temp1;
    } else {
      return newTemp;
    }
  };

  daysFilter = async (g, newTemp) => {
    // console.log(newTemp, "days  in dfilter", g);
    if (Array.isArray(g)) {
      let temp = g.map(g =>
        g.value !== "all" && g.value !== undefined
          ? newTemp.filter(a => a.Schedule.Days.indexOf(g.value) > -1)
          : newTemp
      );
      // console.log(temp, "temp in dfilter");
      let i, temp1;
      temp1 = g.length && [...temp[0]];
      if (g.length > 1) {
        for (i = 1; i < g.length; i++) {
          // console.log([...temp[i], ...temp[0]], "timesl");
          temp1 = await [...temp[i], ...temp1];
        }
      }

      temp1 = g.length ? [...new Set(temp1)] : newTemp;
      // console.log(temp1, "temp1 in dayfilter", [...new Set(temp1)]);
      await this.setTemplates(temp1);
      return temp1;
    } else {
      return newTemp;
    }
  };

  statusFilter = (status, newTemp) => {
    let temp;
    switch (status) {
      case "Active":
        temp = newTemp.filter(e => {
          return e.State == "Active";
        });
        break;
      case "Paused":
        temp = newTemp.filter(e => {
          return e.State == "Paused";
        });
        break;
      case "Completed":
        temp = newTemp.filter(e => {
          return e.State == "Completed";
        });
        break;
      default:
        temp = newTemp;
    }
    this.setTemplates(temp);

    return temp;
  };

  applyFilter = async f => {
    console.log(f);
    const { newTemp } = this.state;

    // let allFilters = Object.keys(Filters).map(key => {
    //   return { [key]: Filters[key] };
    // });
    let a = newTemp;
    let b = await this.durationFilter(f.duration, a);

    let c = await this.gameFilter(f.game, b);
    let d = await this.timeslotFilter(f.timeslots, c);
    let e = await this.daysFilter(f.repeat, d);
    await this.statusFilter(f.status, e);
  };

  handleVariable = e => {
    this.setState({ variable: e.target.value }, () =>
      console.log(this.state.variable)
    );
  };

  filterData = (variable, query) => {
    let data =
      variable != "all"
        ? this.state.initialData.filter(el => {
            return (
              el[variable]
                .toLowerCase()
                .trim()
                .search(query.toLowerCase().trim()) !== -1
            );
          })
        : "";
    this.state.search !== ""
      ? data
        ? this.setState({ data })
        : ""
      : this.setState({ data: this.state.initialData });
  };

  handleSearch = e => {
    let variable = this.state.variable;
    this.setState({ search: e.target.value }, () => {
      //   console.log(this.state.search);
      this.filterData(variable, this.state.search);
    });
  };

  handleStartTime = event => {
    console.log(event);
    this.setState({ startTime: event }, () => {
      console.log(event.toDate());
      //   this.setStep(diff);
    });
  };

  handleEndTime = event => {
    // console.log(event.toDate());
    this.setState({ endTime: event }, () => {
      //   console.log(this.state.startTime, diff);
      //   this.setStep(diff);
    });
  };

  navigate = action => {
    if (action == "Prev") {
      let prev = dates.subtract(moment(this.state.label), 1, "day");
      this.setState({
        label: moment(prev).format("ddd,LL")
      });
    } else {
      let next = dates.add(moment(this.state.label), 1, "day");
      this.setState({ label: moment(next).format("ddd,LL") });
    }
  };
  render() {
    const { label, allRooms, allTemplates, currentTemplates } = this.state;
    const { history, games } = this.props;
    return (
      <Home title="Rooms">
        {/* <div className="rbc-toolbar">
          <span className="rbc-btn-group">
            <img
              src={leftNav}
              alt="chevron-left"
              onClick={() => this.navigate("Prev")}
            />
            <span className="rbc-toolbar-label">{label}</span>
            <img
              src={rightNav}
              alt="chevron-right"
              onClick={() => this.navigate("Next")}
            />
          </span>
          
          <span className="calendar-view">
            <img
              src={calendarIcon}
              alt="calendar-icon"
              onClick={() => history.push("/dashboard")}
            />
          </span>
        </div> */}
        <hr />
        <div className="filters">
          <Form.Select
            label="Game Duration"
            name="duration"
            onChange={this.handleChange}
          >
            <option value="all">All</option>
            <option value="120">0-2 hours</option>
            <option value="240">2-4 hours</option>
            <option value="360">4-6 hours</option>
            <option value="720">6-12 hours</option>
            <option value="1440">12+ hours</option>{" "}
          </Form.Select>
          <div>
            <label>Timeslots</label>
            <Select
              placeholder="All"
              defaultValue="all"
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              isSearchable={false}
              isMulti
              // menuIsOpen
              value={this.state.Filters.timeslots}
              onChange={e => this.handleSelect(e, "timeslots")}
              options={slots}
              name="timeslots"
            />
          </div>

          <Form.Select
            label="Status"
            name="status"
            onChange={this.handleChange}
          >
            <option value="Active">Active</option>
            <option value="Paused">Paused</option>
            <option value="Completed">Completed</option>
          </Form.Select>

          <div>
            <label>Repeat</label>
            <Select
              placeholder="All"
              defaultValue="all"
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              isSearchable={false}
              isMulti
              value={this.state.Filters.repeat}
              onChange={e => this.handleSelect(e, "repeat")}
              options={days}
              name="repeat"
            />
          </div>
          <Form.Select label="Game" name="game" onChange={this.handleChange}>
            <option value="all">All</option>
            {games.map(e => {
              return (
                <option key={e.Name} value={e.Name}>
                  {e.Name}
                </option>
              );
            })}
          </Form.Select>
        </div>
        <hr />
        <div className="table-schedule">
          <ScheduleBox
            data={currentTemplates}
            delete={this.handleArchive}
            // delete={this.props.deleteRooms}
            rooms={allRooms}
          />
        </div>
        {allTemplates.length && (
          <Pagination
            // label={this.state.label}
            totalRecords={this.state.templates.length}
            pageLimit={16}
            pageNeighbours={0}
            onPageChanged={this.onPageChanged}
          />
        )}
      </Home>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    rooms: state.blockState.rooms,
    templates: state.blockState.templates,
    pausedTemplates: state.blockState.pausedTemplates,
    games: state.blockState.games,
    router: state.router
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      requestRooms,
      requestTemplates,
      requestGames
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchedulerList);
