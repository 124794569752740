import * as React from "react";
import Iframe from "react-iframe";

class Studio extends React.Component {
  public render() {
    return (
      <Iframe
        // width="1440"
        // height="700"
        url="https://datastudio.google.com/embed/reporting/1F4EzQxEen_3bOopOGcg2UvYQELchi-Ru/page/1M"
        frameborder="0"
        style="border:0"
        allowFullScreen
      />
    );
  }
}

export default Studio;
