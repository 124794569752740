import * as React from "react";
import leftNav from "../assets/nav1.png";
import rightNav from "../assets/nav2.png";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

class Pagination extends React.Component {
  pageLimit: number;
  totalRecords: number;
  pageNeighbours: number;
  totalPages: number;

  constructor(props) {
    super(props);
    const { totalRecords = null, pageLimit = 30, pageNeighbours = 0 } = props;

    this.pageLimit = typeof pageLimit === "number" ? pageLimit : 30;
    this.totalRecords = typeof totalRecords === "number" ? totalRecords : 0;

    // pageNeighbours can be: 0, 1 or 2
    this.pageNeighbours =
      typeof pageNeighbours === "number"
        ? Math.max(0, Math.min(pageNeighbours, 2))
        : 0;

    this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);

    this.state = { currentPage: 1 };
  }

  componentDidMount() {
    this.gotoPage(1);
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.label != this.props.label) {
  //     this.gotoPage(1);
  //   }
  // }

  gotoPage = page => {
    // console.log(this.props);
    let totalPages = Math.ceil(this.props.totalRecords / this.props.pageLimit);

    const { onPageChanged = f => f } = this.props;
    const currentPage = Math.max(0, Math.min(page, totalPages));
    // console.log(page, "page", Math.min(page, this.totalPages), "crnt[age");
    const paginationData = {
      currentPage,
      totalPages: totalPages,
      pageLimit: this.props.pageLimit,
      totalRecords: this.props.totalRecords
    };
    // console.log(paginationData, "pagiantiondata");
    this.setState({ currentPage }, () => onPageChanged(paginationData));
  };

  handleClick = page => evt => {
    evt.preventDefault();
    this.gotoPage(page);
  };

  handleMoveLeft = evt => {
    evt.preventDefault();

    this.gotoPage(this.state.currentPage - this.pageNeighbours * 2 - 1);
  };

  handleMoveRight = evt => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage + this.pageNeighbours * 2 + 1);
  };

  fetchPageNumbers = () => {
    const totalPages = this.totalPages;
    const currentPage = this.state.currentPage;
    const pageNeighbours = this.pageNeighbours;

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  render() {
    if (!this.totalRecords || this.totalPages === 1) return null;

    const { currentPage } = this.state;
    const pages = this.fetchPageNumbers();

    return (
      <React.Fragment>
        <nav aria-label="Data Pagination">
          <ul className="pagination">
            <span className="rbc-btn-group">
              <img
                src={leftNav}
                alt="chevron-left"
                onClick={
                  this.state.currentPage > 1 ? this.handleMoveLeft : null
                }
              />

              <span className="rbc-toolbar-label">
                {`Page ${this.state.currentPage} out of ${Math.ceil(
                  this.props.totalRecords / this.props.pageLimit
                )}`}
              </span>

              <img
                src={rightNav}
                alt="chevron-right"
                onClick={this.handleMoveRight}
              />
            </span>
          </ul>
        </nav>
      </React.Fragment>
    );
  }
}

export default Pagination;
