// import * as React from "react";
import * as fetch from "isomorphic-fetch";
import { AuthConsumer } from "../Contexts/AuthContext";
import { DefaultHaberdasher, Hat } from "../rpc/haberdasher/service";
import { TwirpError } from "../rpc/twirp";

interface Props {
  idToken: string;
}
// class BaseHaberDasher extends React.Component<Props> {
//   private fetchMakeHat() {
//     const haberdasher = new DefaultHaberdasher(
//       "http://localhost:8080",
//       fetch,
//       this.props.idToken
//     );

//     haberdasher
//       .makeHat({ inches: 10 })
//       .then((hat: Hat) => {
//         console.log("Example makeHat response:\n");
//         console.log(hat);
//         console.log("");
//       })
//       .catch((err: Error) => {
//         console.error(err);
//       });
//   }

//   private fetchMakeHat2() {
//     const haberdasher = new DefaultHaberdasher(
//       "http://localhost:8080",
//       fetch,
//       this.props.idToken
//     );

//     haberdasher
//       .makeHat({ inches: -1 })
//       .then((hat: Hat) => {
//         console.log(hat);
//       })
//       .catch((err: TwirpError) => {
//         console.log("Example makeHat error:\n");
//         console.error(err.code + ": " + err.message);
//       });
//   }

//   render() {
//     return (
//       <>
//         <button onClick={() => this.fetchMakeHat()}>Random Request</button>
//         <button onClick={() => this.fetchMakeHat2()}>Random (-1)</button>
//       </>
//     );
//   }
// }

export const Abc = token => token;

const HaberDasher = () => (
  <AuthConsumer>{({ idToken }: any) => <Abc idToken={idToken} />}</AuthConsumer>
);

export default HaberDasher;
