import {
  cancel,
  cancelled,
  put,
  takeEvery,
  all,
  call,
  take,
  fork
} from "redux-saga/effects";
import * as firebase from "firebase/app";
import "firebase/auth";
import { rsf } from "../firestore";
import * as types from "../constants/ActionTypes";

const authProvider = new firebase.auth.GoogleAuthProvider();

function* loginSaga() {
  yield fork(authorize, authProvider);
}

function* credentialSaga(id_token: any) {
  let credential;
  try {
    credential = firebase.auth.GoogleAuthProvider.credential(id_token);

    const data = yield call(
      rsf.auth.signInAndRetrieveDataWithCredential,
      credential
    );
    if (data.additionalUserInfo.profile.hd !== "getmega.com") {
      yield cancel(data);
    }

    yield put({ type: types.LOGIN_SUCCESS, data });
  } catch (error) {
    yield put({ type: types.LOGIN_ERROR, error });
  } finally {
    if (yield cancelled()) {
      yield call(logoutSaga);
    }
  }
}

function* authorize(authProvider: any) {
  try {
    const data = yield call(rsf.auth.signInWithPopup, authProvider);
    yield call(credentialSaga, data.idToken);
    localStorage.setItem("loading", "true");
  } catch (error) {
    yield put({ type: types.LOGIN_ERROR, error });
  } finally {
    if (yield cancelled()) {
      yield call(logoutSaga);
    }
  }
}

function* syncUserSaga() {
  const channel = yield call(rsf.auth.channel);

  while (true) {
    const { user } = yield take(channel);

    if (user) {
      const idToken = yield user.getIdToken();
      yield put({ type: types.SYNC_USER, user, idToken });
      yield put({ type: types.CHECK_USER, user });
    } else {
      localStorage.removeItem("loading");
      yield put({ type: types.SYNC_USER, user: null });
    }
  }
}

function* logoutSaga() {
  try {
    const data = yield call(rsf.auth.signOut);
    yield put({ type: types.LOGOUT_SUCCESS, data });
    localStorage.removeItem("loading");
  } catch (error) {
    yield put({ type: types.LOGOUT_ERROR, error });
  }
}

function* watchLogin() {
  yield takeEvery(types.LOGIN_REQUEST, loginSaga);
}

function* watchLogout() {
  yield takeEvery(types.LOGOUT_REQUEST, logoutSaga);
}

export default function* loginRootSaga() {
  yield fork(syncUserSaga), yield all([watchLogin(), watchLogout()]);
}
