import * as Yup from "yup";

Yup.setLocale({
  mixed: {
    notType: function notType(_ref) {
      switch (_ref.type) {
        case "number":
          return "Should be a Number";
        case "string":
          return "Should be String";
        default:
          return "Wrong type error ";
      }
    }
  }
});

export default Yup;
