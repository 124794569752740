import * as React from "react";
import { AuthConsumer } from "../Contexts/AuthContext";
import { Redirect } from "react-router-dom";

class Logout extends React.Component {
  render() {
    const { from } = {
      from: { pathname: "/login" }
    };
    const { to } = {
      to: { pathname: "/dashboard" }
    };
    return (
      <AuthConsumer>
        {({ logout, isAuth }: any) => {
          logout();
          if (!isAuth) {
            return <Redirect to={from} />;
          } else {
            return <Redirect to={to} />;
          }
        }}
      </AuthConsumer>
    );
  }
}

export default Logout;
