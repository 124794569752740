import * as types from "../constants/ActionTypes";

export const loginRequest = () => {
  return {
    type: types.LOGIN_REQUEST
  };
};

export const loginSuccess = (credential: any) => {
  return {
    type: types.LOGIN_SUCCESS,
    credential
  };
};

export const loginError = (data: any) => {
  return {
    type: types.LOGIN_ERROR,
    payload: data
  };
};

export const syncUser = (user: any, idToken: string) => ({
  type: types.SYNC_USER,
  user,
  idToken
});

export const checkUser = (user: any) => ({
  type: types.CHECK_USER,
  user
});

export const logoutRequest = () => ({
  type: types.LOGOUT_REQUEST
});

export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS
});

export const logoutError = (error: any) => ({
  type: types.LOGOUT_ERROR,
  error
});
