import * as React from "react";
import { Grid, Header as H } from "tabler-react";
import { Link } from "react-router-dom";

const Home = props => {
  return (
    <div className="wrapper">
      <Grid.Row>
        <Grid.Col>
          <Link to="/dashboard">Home</Link>
        </Grid.Col>
      </Grid.Row>
      {props.title && (
        <Grid.Row>
          <Grid.Col className="home-title">
            <H.H4>{props.title}</H.H4>
          </Grid.Col>
        </Grid.Row>
      )}
      {props.children}
    </div>
  );
};

export default Home;
