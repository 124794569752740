import * as React from "react";
import { Switch, Route } from "react-router-dom";
// import App from "./app";
import Login from "../components/login";
import Studio from "../components/studio";
import Graph from "../components/graph";
import AuthProvider from "../Contexts/AuthContext";
import ProtectedRoute from "./protectedRoute";
import Header from "../components/header";
import HaberDasher from "../components/haberdasher";
import SchedulerCalendar from "../containers/Scheduler/schedulerCalendar";
import CreateBlock from "../containers/Blocks/createBlock";
import CreateSchedule from "../containers/Template/createSchedule";
import SchedulerList from "../containers/Scheduler/schedulerList";
import BlockList from "../containers/Blocks/blockList";
import ScheduleList from "../containers/Template/scheduleList";
import CreateRoom from "../containers/Room/createRoom";
import RoomVisualizer from "../containers/Room/visualizer";
import Logout from "../components/logout";

const ReactRouter = () => {
  return (
    <AuthProvider>
      <Header />
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <ProtectedRoute exact path="/haberdasher" component={HaberDasher} />
        <ProtectedRoute exact path="/dashboard" component={SchedulerCalendar} />
        <ProtectedRoute exact path="/graph" component={Graph} />
        <ProtectedRoute exact path="/analytics" component={Studio} />
        <ProtectedRoute exact path="/reports" />
        <Route exact path="/block/create" render={() => <CreateBlock />} />
        <Route exact path="/block/edit" render={() => <CreateBlock />} />
        <ProtectedRoute
          exact
          path="/schedule/create"
          component={CreateSchedule}
        />
        <ProtectedRoute exact path="/room/list" component={SchedulerList} />
        <ProtectedRoute exact path="/blocks/list" component={BlockList} />
        <ProtectedRoute exact path="/schedule/list" component={ScheduleList} />
        <ProtectedRoute exact path="/room/create" component={CreateRoom} />
        <ProtectedRoute
          exact
          path="/room/visualize"
          component={RoomVisualizer}
        />
      </Switch>
    </AuthProvider>
  );
};

export default ReactRouter;
