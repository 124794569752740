import * as React from "react";
import { Grid, Table, Icon } from "tabler-react";
import * as fetch from "isomorphic-fetch";
import { Checkbox, Alignment } from "@blueprintjs/core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { requestPauseIntervals } from "../../actions/formActions";
import greenCheck from "../../assets/check-green.png";
import editImg from "../../assets/Edit.png";
import deleteImg from "../../assets/Delete.png";
import ScheduleDialog from "./scheduleDialog";
import { AuthConsumer } from "../../Contexts/AuthContext";
import PausePlayDialog from "./pausePlayDialog";
import { TwirpError } from "../../rpc/service/twirp";
import { TemplateService } from "../../rpc/service/pb";

const days = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY"
];

interface Props {
  data: any;
  delete: any;
  rooms: any;
  requestPauseIntervals: any;
  pauseIntervals: any;
}

class ScheduleBox extends React.Component<Props> {
  state = {
    open: false,
    openForPause: false,
    rooms: [],
    startDate: null,
    endDate: null,
    templateId: "",
    error: null,
    token: null
  };

  handleSubmit = async (values, token) => {
    // console.log(this.state.startDate, " #### ", this.state.endDate);
    if (process.env.REACT_APP_ENV == "DEV") {
      token = "dev";
    }
    const pause = new TemplateService(process.env.REACT_APP_FETCH_URL, fetch);
    let val = {
      template_id: this.state.templateId,
      start_time: this.state.startDate,
      end_time: this.state.endDate
    };
    pause
      .pauseTemplate(val, {
        authorization: `bearer ${token}`
      })

      .catch((err: TwirpError) => {
        console.error(err.code + ": " + err.message);
        this.setState({ error: err.message });
        this.props.requestPauseIntervals(this.state.templateId);
      });
  };

  handleCancel = async (id, token) => {
    if (process.env.REACT_APP_ENV == "DEV") {
      token = "dev";
    }
    const cancel = new TemplateService(process.env.REACT_APP_FETCH_URL, fetch);
    let val = {
      template_id: this.state.templateId,
      template_pause_interval_id: id
    };
    cancel
      .cancelTemplatePauseInterval(val, {
        authorization: `bearer ${token}`
      })
      .catch((err: TwirpError) => {
        console.error(err.code + ": " + err.message);
        this.setState({ error: err.message });
        this.props.requestPauseIntervals(this.state.templateId);
      });
  };

  handleStartDate = event => this.setState({ startDate: event });

  handleEndDate = event => this.setState({ endDate: event });

  openModal = tempId => {
    let rooms = this.props.rooms.filter(
      f => f.Template && f.Template.Id && f.Template.Id == tempId
    );

    this.setState({ open: true, rooms });
  };
  openForPauseModal = async (tempId, token) => {
    if (process.env.REACT_APP_ENV == "DEV") {
      token = "dev";
    }
    // let rooms = this.props.rooms.filter(
    //   f => f.Template && f.Template.Id && f.Template.Id == tempId
    // );

    await this.props.requestPauseIntervals(tempId);
    this.setState({ openForPause: true, templateId: tempId, token });
  };
  handleClose = () =>
    this.setState({ open: false, openForPause: false, error: null });
  render() {
    const { data } = this.props;
    // console.log(this.props.pauseIntervals, "pause");

    return (
      <AuthConsumer>
        {({ idToken }: any) => (
          <div className="schedule-box">
            <Grid.Row>
              {data.map((temp, i) => (
                <Grid.Col key={i} md={6} className="box-container">
                  <Grid.Row className="box">
                    <Grid.Col md={6} className="left-col">
                      <div
                        className="room"
                        onClick={() => this.openModal(temp.id)}
                      >
                        <p>{temp.Name}</p>
                      </div>
                      <div className="game">
                        <p>{`Game : ${temp.GameName}`}</p>
                      </div>
                      <Table className="section-1">
                        <Table.ColHeader>Money Distribution</Table.ColHeader>
                        <Table.ColHeader>Entry</Table.ColHeader>

                        <Table.Body>
                          <Table.Col>{`${temp.Block.PercentageWinners.toFixed(
                            2
                          )}% Winner`}</Table.Col>
                          <Table.Col>{`${
                            temp.Block.EntryFeeValue
                          } Gems`}</Table.Col>
                        </Table.Body>
                      </Table>
                      <Table>
                        <Table.ColHeader>T1-T2</Table.ColHeader>
                        <Table.ColHeader>T2-T3</Table.ColHeader>
                        <Table.ColHeader>T3-T4</Table.ColHeader>
                        <Table.Body>
                          <Table.Col>{temp.Block.T1T2DurationMin}</Table.Col>
                          <Table.Col>{temp.Block.T2T3DurationMin}</Table.Col>
                          <Table.Col>
                            {temp.Block.T3T4DurationMin}
                          </Table.Col>{" "}
                        </Table.Body>
                      </Table>
                    </Grid.Col>
                    <Grid.Col md={6} className="right-col">
                      <div className="actions">
                        <Icon
                          name="pause"
                          onClick={() =>
                            this.openForPauseModal(temp.id, idToken)
                          }
                        />
                        <img src={editImg} />
                        <img
                          src={deleteImg}
                          onClick={() => this.props.delete(temp.id, idToken)}
                        />
                      </div>
                      <div className="header">
                        <p>Schedule</p>
                      </div>
                      <div className="schedule-row">
                        <div className="days">
                          {days.map((o, i) => (
                            <Checkbox
                              key={i}
                              alignIndicator={Alignment.LEFT}
                              disabled
                              inline={true}
                              large={false}
                              checked={temp.Schedule.Days.includes(o)}
                              value={o}
                            />
                          ))}
                        </div>
                        <div className="offset">
                          <Table>
                            <Table.ColHeader>Offset</Table.ColHeader>
                            <Table.Body>
                              <Table.Col>{`${temp.OffsetMins} mins`}</Table.Col>
                            </Table.Body>
                          </Table>
                        </div>
                      </div>
                      <div className="respawn">
                        <p>Respawn At</p>
                        <div className="respawn-body">
                          <Grid.Row className="respawn-row">
                            {temp.Schedule.SpawnWindows.map((m, i) => (
                              <Grid.Col key={i} md={6}>
                                <img src={greenCheck} />
                                <p>{m.split("_").join(" ")}</p>
                              </Grid.Col>
                            ))}
                          </Grid.Row>
                        </div>
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                </Grid.Col>
              ))}
            </Grid.Row>
            <ScheduleDialog
              isOpen={this.state.open}
              handleClose={this.handleClose}
              data={this.state.rooms}
            />
            <PausePlayDialog
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              isOpen={this.state.openForPause}
              handleClose={this.handleClose}
              token={this.state.token}
              pauseIntervals={this.props.pauseIntervals}
              handleStartDate={this.handleStartDate}
              handleEndDate={this.handleEndDate}
              handleSubmit={this.handleSubmit}
              handleCancel={this.handleCancel}
              error={this.state.error}
            />
          </div>
        )}
      </AuthConsumer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    pauseIntervals: state.blockState.pauseIntervals
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      requestPauseIntervals
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleBox);
