import * as React from "react";
import BigCalendar from "react-big-calendar";
import * as moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import dates from "date-arithmetic";
import { connect } from "react-redux";
import Home from "../../components/home";
import SchedulerToolbar from "./schedulerToolbar";
import ScheduleDialog from "./scheduleDialog";
import { bindActionCreators } from "redux";
import { requestRooms, requestGames } from "../../actions/formActions";

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = BigCalendar.momentLocalizer(moment); // or globalizeLocalizer

interface Props {
  requestRooms: any;
  requestGames: any;
  getRooms: any;
  games: any;
}

class SchedulerCalendar extends React.Component<Props> {
  state = {
    startTime: new Date(new Date(new Date().setHours(0)).setMinutes(0)),
    endTime: new Date(new Date(new Date().setHours(23)).setMinutes(59)),
    step: 30,
    open: false,
    modalRooms: [],
    rooms: [
      {
        id: null,
        title: null,
        start: new Date(),
        t2: null,
        t3: null,
        end: null,
        gems: null,
        type: null,
        respawn: null,
        created_by: null,
        date: null,
        isSimulated: null,
        tempId: null
      }
    ],
    resourceMap: []
  };

  getRooms = () => {
    // console.log(this.props.getRooms, "in getrooms");
    const rooms = this.props.getRooms.map(room => {
      return {
        id: room.id,
        resourceId: room.Game && room.Game.Id, //gameid
        title: room.Game && room.Game.Name,
        start:
          room.T1Timestamp && !room.IsSimulated
            ? new Date(Number(room.T1Timestamp.seconds) * 1000)
            : new Date(room.T1Timestamp),
        t2:
          room.T2Timestamp && !room.IsSimulated
            ? new Date(Number(room.T2Timestamp.seconds) * 1000)
            : new Date(room.T2Timestamp),
        t3:
          room.T3Timestamp && !room.IsSimulated
            ? new Date(Number(room.T3Timestamp.seconds) * 1000)
            : new Date(room.T3Timestamp),
        end:
          room.T4Timestamp && !room.IsSimulated
            ? new Date(Number(room.T4Timestamp.seconds) * 1000)
            : new Date(room.T4Timestamp),
        gems: room.Template && room.Template.Block.EntryFeeValue,
        type:
          room.Template &&
          String(room.Template.Block.PercentageWinners.toFixed(2)),
        wdf: room.Template && room.Template.Block && room.Template.Block.Wdf,
        tempId: room.Template && room.Template.Id,
        created_by: room.Template && room.Template.CreatedBy,
        date:
          room.Template &&
          room.Template.CreatedAt &&
          new Date(Number(room.Template.CreatedAt.seconds) * 1000),
        isSimulated: room.IsSimulated,
        openModal: this.openModal
      };
    });

    this.setState(
      { rooms }
      // , () =>
      // console.log(this.state.rooms, "in didmount")
    );
  };

  openModal = tempId => {
    let rooms = this.props.getRooms.filter(
      f => f.Template && f.Template.Id && f.Template.Id == tempId
    );

    this.setState({ open: true, modalRooms: rooms });
  };

  handleClose = () => this.setState({ open: false });

  fetchData = async () => {
    await this.props.requestRooms();
    await this.props.requestGames();
  };

  componentDidMount = async () => {
    await this.fetchData();

    this.getRooms();
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.getRooms != this.props.getRooms) {
      this.getRooms();
    }
    if (prevProps.games != this.props.games) {
      this.getGames();
    }
  };

  getGames = () => {
    // console.log(this.props.games, "----games----");
    const games = this.props.games.map(game => {
      return {
        resourceId: game.id,
        resourceTitle: game.Name
      };
    });
    this.setState({ resourceMap: games });
  };

  setStep = diff => {
    if (diff <= 360) {
      //6 hours
      this.setState({ step: 15 });
    }
    if (diff <= 120) {
      //2 hours
      this.setState({ step: 5 });
    }
  };

  handleStartTime = event => {
    // console.log(event.toDate());
    this.setState({ startTime: event }, () => {
      const diff = dates.diff(
        this.state.startTime,
        this.state.endTime,
        "minutes"
      );
      //   console.log(diff);

      this.setStep(diff);
    });
  };
  handleEndTime = event => {
    // console.log(event.toDate());
    this.setState({ endTime: event }, () => {
      const diff = dates.diff(
        this.state.startTime,
        this.state.endTime,
        "minutes"
      );
      //   console.log(this.state.startTime, diff);

      this.setStep(diff);
    });
  };
  public render() {
    // console.log(this.props);
    return (
      <Home title="Schedule - Calendar Mode">
        <div className="scheduler">
          <SchedulerToolbar
            handleStartTime={this.handleStartTime}
            handleEndTime={this.handleEndTime}
            startTime={this.state.startTime}
            endTime={this.state.endTime}
            {...this.props}
          />
          <BigCalendar
            popup
            colorType="blue"
            min={this.state.startTime}
            max={this.state.endTime}
            step={this.state.step}
            timeslots={1}
            localizer={localizer}
            events={this.state.rooms}
            defaultView={BigCalendar.Views.DAY}
            defaultDate={new Date()}
            views={{ day: true }}
            showMultiDayTimes
            startAccessor="start"
            endAccessor="end"
            resources={this.state.resourceMap}
            resourceIdAccessor="resourceId"
            resourceTitleAccessor="resourceTitle"
            tooltipAccessor={() => null}
          />
          <ScheduleDialog
            isOpen={this.state.open}
            handleClose={this.handleClose}
            data={this.state.modalRooms}
          />
        </div>
      </Home>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    getRooms: state.blockState.rooms,
    games: state.blockState.games
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      requestRooms,
      requestGames
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchedulerCalendar);
