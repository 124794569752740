import * as React from "react";
import { Formik } from "formik";
import { Header as H, Form, Button, Grid, Table, Alert } from "tabler-react";
import { Checkbox, Alignment } from "@blueprintjs/core";
import { connect } from "react-redux";
import Select from "react-select";
import * as fetch from "isomorphic-fetch";
import DatePicker from "react-datepicker";
import Yup from "../../constants/validation";
import { TwirpError } from "../../rpc/service/twirp";
import greenCheck from "../../assets/check-green.png";
import Home from "../../components/home";
import { bindActionCreators } from "redux";
import {
  requestGames,
  requestBlocks,
  requestSchedule,
  requestRooms,
  requestTags
} from "../../actions/formActions";
import { TemplateService } from "../../rpc/service/pb";
import { AuthConsumer } from "../../Contexts/AuthContext";
import RoomVisualiser from "./visualizer";

const days = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY"
];

interface Props {
  requestGames: any;
  requestBlocks: any;
  requestTags: any;
  requestSchedule: any;
  requestRooms: any;
  formData: any;
  isReady: any;
  user: any;
  history: any;
}

const RoomSchema = Yup.object().shape({
  game_name: Yup.string().required("Required"),
  offset_mins: Yup.number().nullable(),
  schedule_id: Yup.string().required("Required"),
  block_id: Yup.string().required("Required")
});

class CreateRoom extends React.Component<Props> {
  state = {
    initValues: {
      game: "",
      schedule: "",
      block: ""
    },
    isFeatured: false,
    newRooms: [],
    tags: [],
    oldRooms: [],
    rooms: [],
    startDate: null,
    endDate: null,
    error: ""
  };
  handleStartDate = event => {
    // console.log(event);
    this.setState({ startDate: event });
  };
  handleEndDate = event => {
    // console.log(event);
    this.setState({ endDate: event });
  };

  private handleVisualise = async (values, token) => {
    console.log(values, "values");
    this.setState({ rooms: this.state.oldRooms });
    if (process.env.REACT_APP_ENV == "DEV") {
      token = "dev-5";
    }
    let tags = this.state.tags.length && this.state.tags;
    let template_tags = tags.length
      ? tags.map(t => {
          return {
            target_point: t.target_point,
            tag_ids: [t.value]
          };
        })
      : null;
    let game = this.props.formData.games.find(v => v.Name == values.game_name);
    let game_id = game.id;
    let val = {
      ...values,
      template_tags,
      is_featured: this.state.isFeatured,
      game_id
    };
    const visualise = new TemplateService(
      process.env.REACT_APP_FETCH_URL,
      fetch
    );

    let response = await visualise.simulateTemplate(val, {
      authorization: `bearer ${token}`
    });
    let newRooms = JSON.parse(JSON.stringify(response.rooms));
    newRooms = newRooms.map(e => {
      return {
        Template: {
          Block: {
            EntryFeeValue: e.template.block.entry_fee_value,
            PercentageWinners: e.template.block.percentage_winners
          },
          CreatedBy: e.template.created_by,
          CreatedAt: e.template.created_at
        },
        T1Timestamp: e.t1_timestamp,
        T2Timestamp: e.t2_timestamp,
        T3Timestamp: e.t3_timestamp,
        T4Timestamp: e.t4_timestamp,
        id: e.template.game_id,
        IsSimulated: e.is_simulated,
        Game: {
          Name: values.game_name
        }
      };
    });
    newRooms = [...this.state.rooms, ...newRooms];
    this.setState({ rooms: newRooms });
  };

  private handleSubmit(values, token) {
    if (process.env.REACT_APP_ENV == "DEV") {
      token = "dev";
    }
    let game = this.props.formData.games.find(v => v.Name == values.game_name);
    let game_id = game.id;
    let tags = this.state.tags.length && this.state.tags;
    let template_tags = tags.length
      ? tags.map(t => {
          return {
            target_point: t.target_point,
            tag_ids: [t.value]
          };
        })
      : null;
    let val = {
      ...values,
      template_tags,
      game_id,
      is_featured: this.state.isFeatured,
      start_time: this.state.startDate,
      end_time: this.state.endDate,
      created_by: this.props.user ? this.props.user.displayName : null
    };
    // console.log(val, "vaaaaallllll");
    const room = new TemplateService(process.env.REACT_APP_FETCH_URL, fetch);

    room.submitTemplate(val, { authorization: `bearer ${token}` }).then(() => {
        console.log("Submit template responded with success")
        this.props.history.push("/room/list");
      }).catch((err: TwirpError) => {
        console.log("Submit template responded with failure")
        console.log(err.code + ": " + err.message);
        this.setState({ error: err.message });
      });
  }
  handleCheckbox = () => {
    this.setState({ isFeatured: !this.state.isFeatured });
  };

  submitAction = undefined;
  fetchData = async () => {
    await this.props.requestGames();
    await this.props.requestBlocks();
    await this.props.requestSchedule();
    //await this.props.requestRooms();
    await this.props.requestTags();
  };
  componentDidMount = async () => {
    await this.fetchData();
    this.setState(
      { rooms: this.props.formData.rooms, oldRooms: this.props.formData.rooms }
      // () =>
      // console.log(this.state.rooms, "rooms in did mount")
    );
  };
  handleSelect = data => {
    this.setState(
      { tags: data }
      // , () => console.log(this.state.tags)
    );
  };
  componentDidUpdate = async (prevProps, prevState) => {
    if (this.props.formData.length == 0) {
      await this.fetchData();
    }
    if (prevProps.formData.rooms != this.props.formData.rooms) {
      this.setState(
        {
          rooms: this.props.formData.rooms,
          oldRooms: this.props.formData.rooms
        }
        // () =>
        // console.log(this.state.rooms, "rooms in did update")
      );
    }
  };
  render() {
    // console.log(this.props);
    const { formData } = this.props;
    return (
      <AuthConsumer>
        {({ idToken }: any) => (
          <div className="room-form">
            <Home>
              <H.H4>Create new Set</H.H4>
              {
                this.state.error == "" ?
                '':
                <Alert type="danger">
                {this.state.error}</Alert>
              }
              <hr />
              <Formik
                enableReinitialize
                initialValues={{
                  game_name: "",
                  offset_mins: null,
                  schedule_id: "",
                  block_id: "",
                  template_tags: ""
                }}
                validationSchema={RoomSchema}
                onSubmit={(values, actions) => {
                  if (this.submitAction == "submit") {
                    this.handleSubmit(values, idToken);
                    setTimeout(() => {
                      actions.setSubmitting(false);
                      // actions.resetForm();
                    }, 400);
                  } else {
                    this.handleVisualise(values, idToken);

                    setTimeout(() => {
                      actions.setSubmitting(false);
                      // actions.resetForm();
                    }, 400);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  submitForm
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid.Row>
                      <Grid.Col md={4}>
                        <Form.Select
                          name="game_name"
                          label="Choose Game"
                          placeholder="Choose Game"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.game_name}
                        >
                          <option value="">Select</option>
                          {formData.games.map((e, i) => (
                            <option key={i} value={e.Name}>
                              {e.Name}
                            </option>
                          ))}
                        </Form.Select>
                        {errors.game_name && touched.game_name ? (
                          <div className="error">{errors.game_name}</div>
                        ) : null}
                        <Form.Input
                          name="offset_mins"
                          label="Offset Time "
                          placeholder="Offset Time"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.offset_mins}
                        />
                        {errors.offset_mins && touched.offset_mins ? (
                          <div className="error">{errors.offset_mins}</div>
                        ) : null}

                        <label>Select Tags</label>
                        <Select
                          hideSelectedOptions={false}
                          closeMenuOnSelect={false}
                          isSearchable={false}
                          isMulti
                          value={this.state.tags}
                          onChange={e => this.handleSelect(e)}
                          options={formData.tags.map((e, i) => {
                            return {
                              label: e.DisplayName,
                              value: e.id,
                              target_point: e.TargetPoint
                            };
                          })}
                          name="tags"
                        />
                        <div className="featured-room">
                          <Checkbox
                            alignIndicator={Alignment.LEFT}
                            inline={true}
                            large={false}
                            label="Featured Room"
                            checked={this.state.isFeatured}
                            value="true"
                            onChange={this.handleCheckbox}
                          />
                        </div>
                        <br />
                      </Grid.Col>
                      <Grid.Col md={4}>
                        <Form.Select
                          name="schedule_id"
                          label="Select Schedule "
                          placeholder="Select Schedule"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.schedule_id}
                        >
                          <option value="">Select</option>
                          {formData.scheduleList.map((e, i) => (
                            <option key={i} value={e.id}>
                              {e.Name}
                            </option>
                          ))}
                        </Form.Select>
                        {errors.schedule_id && touched.schedule_id ? (
                          <div className="error">{errors.schedule_id}</div>
                        ) : null}
                        {values.schedule_id && (
                          <>
                            {formData.scheduleList
                              .filter(f => f.id == values.schedule_id)
                              .map((m, j) => (
                                <div className="days" key={j}>
                                  {days.map((o, i) => (
                                    <Checkbox
                                      key={i}
                                      alignIndicator={Alignment.LEFT}
                                      disabled
                                      inline={true}
                                      large={false}
                                      checked={m.Days.includes(o)}
                                      value={o}
                                    />
                                  ))}
                                </div>
                              ))}
                          </>
                        )}
                        {values.schedule_id && (
                          <>
                            {formData.scheduleList
                              .filter(f => f.id == values.schedule_id)
                              .map(m => (
                                <div className="respawn">
                                  <p>Respawn At</p>
                                  <div className="respawn-body">
                                    <Grid.Row className="respawn-row">
                                      {m.SpawnWindows.map(e => (
                                        <Grid.Col md={6}>
                                          <img src={greenCheck} />
                                          <p>{e}</p>
                                        </Grid.Col>
                                      ))}
                                    </Grid.Row>
                                  </div>
                                </div>
                              ))}
                          </>
                        )}
                      </Grid.Col>
                      <Grid.Col md={4}>
                        <Form.Select
                          name="block_id"
                          label="Select Block "
                          placeholder="Select Block"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.block_id}
                        >
                          <option value="">Select</option>
                          {formData.blocks.map((e, i) => (
                            <option key={i} value={e.id}>
                              {e.Name}
                            </option>
                          ))}
                        </Form.Select>
                        {errors.block_id && touched.block_id ? (
                          <div className="error">{errors.block_id}</div>
                        ) : null}
                        {values.block_id && (
                          <>
                            {formData.blocks
                              .filter(f => f.id == values.block_id)
                              .map(m => (
                                <div className="block-div">
                                  <Table className="section-1">
                                    <Table.ColHeader>
                                      Money Distribution
                                    </Table.ColHeader>
                                    <Table.ColHeader>Entry</Table.ColHeader>

                                    <Table.Body>
                                      <Table.Col>{`${m.PercentageWinners.toFixed(
                                        2
                                      )}% Winner`}</Table.Col>
                                      <Table.Col>{`${
                                        m.EntryFeeValue
                                      } Gems`}</Table.Col>
                                    </Table.Body>
                                  </Table>
                                  <Table>
                                    <Table.ColHeader>T1-T2</Table.ColHeader>
                                    <Table.ColHeader>T2-T3</Table.ColHeader>
                                    <Table.ColHeader>T3-T4</Table.ColHeader>
                                    <Table.Body>
                                      <Table.Col>{`${
                                        m.T1T2DurationMin
                                      } mins`}</Table.Col>
                                      <Table.Col>{`${
                                        m.T2T3DurationMin
                                      } mins`}</Table.Col>
                                      <Table.Col>{`${
                                        m.T3T4DurationMin
                                      } mins`}</Table.Col>{" "}
                                    </Table.Body>
                                  </Table>
                                </div>
                              ))}
                          </>
                        )}
                      </Grid.Col>
                    </Grid.Row>
                    {/**<Button
                      className="submit"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Visualize
                    </Button>*/}
                    <Grid.Row>
                      <Grid.Col md={3} offset={3}>
                        <label>Start Date</label>
                        <DatePicker
                          placeholderText="Select Date"
                          selected={this.state.startDate}
                          timeInputLabel="Time:"
                          onChange={this.handleStartDate}
                          dateFormat="MM/dd/yyyy h:mm aa"
                          showTimeInput
                        />
                      </Grid.Col>
                      <Grid.Col md={3}>
                        <label>End Date</label>
                        <DatePicker
                          placeholderText="Select Date"
                          selected={this.state.endDate}
                          timeInputLabel="Time:"
                          onChange={this.handleEndDate}
                          dateFormat="MM/dd/yyyy h:mm aa"
                          showTimeInput
                        />
                      </Grid.Col>
                    </Grid.Row>
                    {/**<Grid.Row>
                      <Grid.Col md={12}>
                        {values.game_name && (
                          <RoomVisualiser
                            gameName={values.game_name}
                            rooms={this.state.rooms}
                          />
                        )}
                      </Grid.Col>
                    </Grid.Row> */}

                    <Button
                      disabled={isSubmitting}
                      className="submit"
                      type="submit"
                      onClick={() => {
                        this.submitAction = "submit";
                        submitForm();
                      }}
                    >
                      Schedule
                    </Button>
                  </Form>
                )}
              </Formik>
            </Home>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    formData: state.blockState,
    isReady: state.blockState.isReady,
    router: state.router,
    user: state.loginState.user
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      requestGames,
      requestBlocks,
      requestSchedule,
      requestRooms,
      requestTags
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRoom);
