import * as types from "../constants/ActionTypes";

const initialState = {
  EntryFee: [],
  duration: [],
  PercentageWinners: [],
  blocks: [],
  tags: [],
  wdc:[],
  games: [],
  scheduleList: [],
  rooms: [],
  pauseIntervals: [],
  isReady: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_BLOCKS:
      return {
        ...state,
        blocks: [],
        isReady: false
      };
    case types.REQUEST_GAMES:
      return {
        ...state,
        games: [],
        isReady: false
      };
    case types.REQUEST_TAGS:
      return {
        ...state,
        tags: [],
        isReady: false
      };
      case types.REQUEST_WDC:
      return {
        ...state,
        wdc: [],
        isReady: false
      };
    case types.REQUEST_SCHEDULE:
      return {
        ...state,
        scheduleList: [],
        isReady: false
      };
    case types.REQUEST_ROOMS:
      return {
        ...state,
        rooms: [],
        isReady: false
      };
    case types.REQUEST_TEMPLATES:
      return {
        ...state,
        templates: [],
        pausedTemplates: [],
        completedTemplates: [],
        isReady: false
      };
    case types.REQUEST_PAUSE_INTERVALS:
      return {
        ...state,
        pauseIntervals: [],
        isReady: false
      };
    case types.REQUEST_CREATE_BLOCK_OPTIONS:
      return {
        ...state,
        EntryFee: [],
        duration: [],
        PercentageWinners: [],
        isReady: false
      };
    case types.GET_CREATE_BLOCK_OPTIONS:
      return {
        ...state,
        EntryFee: action.options.EntryFee,
        duration: action.options.T1T2Duration,
        PercentageWinners: action.options.PercentageWinners,
        isReady: true
      };
    case types.GET_BLOCKS:
      return {
        ...state,
        blocks: action.list,
        isReady: true
      };
    case types.GET_GAMES:
      return {
        ...state,
        games: action.games,
        isReady: true
      };
    case types.GET_TAGS:
      return {
        ...state,
        tags: action.tags,
        isReady: true
      };
      case types.GET_WDC:
        return {
          ...state,
          wdc: action.wdc,
          isReady: true
        };
    case types.GET_SCHEDULE:
      return {
        ...state,
        scheduleList: action.scheduleList,
        isReady: true
      };
    case types.GET_ROOMS:
      return {
        ...state,
        rooms: action.rooms,
        isReady: true
      };
    case types.GET_TEMPLATES:
      return {
        ...state,
        templates: action.templates,
        pausedTemplates: action.pausedTemplates,
        completedTemplates: action.completedTemplates,
        isReady: true
      };
    case types.GET_PAUSE_INTERVALS:
      return {
        ...state,
        pauseIntervals: action.pauseIntervals,
        isReady: true
      };
    default:
      return state;
  }
};
