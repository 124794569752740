import * as React from "react";
import { Header as H, Form } from "tabler-react";
import Input from "react-moment-input";
import moment from "moment";
import { connect } from "react-redux";
import Home from "../../components/home";
import BlockRows from "./blockRows";
import Create from "../../assets/fab.png";
import { bindActionCreators } from "redux";
import {
  requestBlocks,
  requestCreateBlockOptions
} from "../../actions/formActions";
import { deleteBlocks } from "../../actions/deleteActions";
import Pagination from "../../components/pagination";
import { WinningsDistributionFunction } from "../../rpc/service/pb";

interface Props {
  blockData: any;
  router: any;
  requestBlocks: any;
  deleteBlocks: any;
  history: any;
  requestCreateBlockOptions: any;
  entryFeeOptions: any;
}

class BlockList extends React.Component<Props> {
  state = {
    dateCreated: moment(),
    blocks: [],
    createdByList: [],
    Filters: {
      duration: "all",
      entryFee: "all",
      distribution: "all",
      sortBy: "all",
      createdBy: "all"
    },
    newTemp: [],
    distributions: [],
    currentBlocks: [],
    currentPage: null,
    totalPages: null
  };

  fetchData = async () => {
    await this.props.requestBlocks();
    await this.props.requestCreateBlockOptions();
  };

  componentDidMount = async () => {
    await this.fetchData();
    this.setState(
      { distributions: Object.values(WinningsDistributionFunction) }
      // ,() => console.log(this.state.distributions)
    );
  };

  onPageChanged = async data => {
    // console.log(data, "-----data-------");
    const { blocks } = await this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentBlocks = blocks.slice(offset, offset + pageLimit);

    this.setState(
      { currentPage, currentBlocks, totalPages }
      // , () =>
      // console.log(this.state.currentBlocks, "curntblock")
    );
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.blockData != this.props.blockData) {
      this.setState(
        { blocks: this.props.blockData, newTemp: this.props.blockData }
        // , () =>
        // console.log(this.state.blocks, "blocks in did update")
      );
      let createdByList = this.props.blockData.map(f => f.CreatedBy);
      // console.log([...new Set(createdByList)]);
      createdByList = [...new Set(createdByList)];
      this.setState({ createdByList });
      let data = {
        currentPage: 1,
        pageLimit: 15,
        totalPages: Math.ceil(this.state.blocks.length / 15),
        totalRecords: this.state.blocks.length
      };
      this.onPageChanged(data);
      this.applyFilter(this.state.Filters);
    }
  };

  edit = obj => {
    console.log(obj);
    this.props.history.push("/block/edit", obj);
  };

  handleChange = event => {
    let Filters = { ...this.state.Filters };
    let name = event.target.name;
    // console.log(name, event.target.value);
    Filters[name] = event.target.value;
    this.setState({ ...this.state.Filters, Filters }, () =>
      this.applyFilter(this.state.Filters)
    );
  };

  entryFeeFilter = (entryFee, newTemp) => {
    let temp =
      entryFee !== "all" && entryFee !== undefined
        ? newTemp.filter(f => {
            return f.EntryFeeValue == entryFee;
          })
        : newTemp;
    this.setBlocks(temp);

    return temp;
  };

  distributionFilter = (dist, newTemp) => {
    // console.log(dist);
    let temp =
      dist !== "all" && dist !== undefined
        ? newTemp.filter(f => {
            return f.Wdf == dist;
          })
        : newTemp;
    this.setBlocks(temp);

    return temp;
  };

  sortFilter = (filter, newTemp) => {
    let temp;
    // console.log(filter);
    switch (filter) {
      case "entryFee":
        temp = newTemp.sort((a, b) => a.EntryFeeValue - b.EntryFeeValue);
        break;
      case "duration":
        temp = newTemp.sort((a, b) => a.DurationMin - b.DurationMin);
        break;
      case "distribution":
        temp = newTemp.sort(
          (a, b) => a.PercentageWinners - b.PercentageWinners
        );
        break;
      case "createdBy":
        temp = newTemp.sort((a, b) => {
          let textA = a.CreatedBy.toUpperCase();
          let textB = b.CreatedBy.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        break;
      default:
        temp = newTemp;
    }
    this.setBlocks(temp);

    return temp;
  };

  createdByFilter = (f, newTemp) => {
    let temp =
      f !== "all" && f !== undefined
        ? newTemp.filter(e => {
            return e.CreatedBy == f;
          })
        : newTemp;
    this.setBlocks(temp);
    return temp;
  };

  applyFilter = async f => {
    const { newTemp } = this.state;

    let a = newTemp;
    let b = await this.durationFilter(f.duration, a);

    let c = await this.entryFeeFilter(f.entryFee, b);
    let d = await this.distributionFilter(f.distribution, c);
    let e = await this.createdByFilter(f.createdBy, d);

    await this.sortFilter(f.sortBy, e);
  };

  durationFilter = (duration, newTemp) => {
    // const { newTemp } = this.state;
    // console.log(newTemp, "newtemp in duration");
    let temp;

    switch (duration) {
      case "120":
        temp = newTemp.filter(e => {
          return e.DurationMin < duration;
        });
        break;
      case "240":
        temp = newTemp.filter(e => {
          return e.DurationMin < duration && e.DurationMin >= 120;
        });
        break;
      case "360":
        temp = newTemp.filter(e => {
          return e.DurationMin < duration && e.DurationMin >= 240;
        });
        break;
      case "720":
        temp = newTemp.filter(e => {
          return e.DurationMin < duration && e.DurationMin >= 360;
        });
        break;
      case "1440":
        temp = newTemp.filter(e => {
          return e.DurationMin < duration && e.DurationMin >= 720;
        });
        break;
      default:
        // console.log(newTemp, "newtemp in defaullt");
        temp = newTemp;
    }

    this.setBlocks(temp);

    return temp;
  };

  setBlocks = temp => {
    let data;
    this.setState(
      {
        blocks: temp
      },
      () => {
        // console.log(this.state.blocks);
        data = {
          currentPage: 1,
          pageLimit: 15,
          totalPages: Math.ceil(this.state.blocks.length / 15),
          totalRecords: this.state.blocks.length
        };
        this.onPageChanged(data);
      }
    );
  };

  handleVariable = e => {
    let a = e.target.name;
    this.setState({ [a]: e.target.value }, () => console.log(this.state));
  };
  handleDate = event => {
    console.log(event);
    this.setState({ dateCreated: event }, () => {
      console.log(event.toDate());
      //   this.setStep(diff);
    });
  };
  render() {
    // console.log(this.props, "list");
    const { entryFeeOptions } = this.props;
    // console.log(entryFeeOptions);
    return (
      <Home>
        <div className="create">
          <H.H3>Blocks</H.H3>
          <img
            src={Create}
            alt="create new"
            onClick={() => this.props.history.push("/block/create")}
          />
        </div>
        <div className="filters">
          <Form.Select
            label="Entry Fee"
            name="entryFee"
            onChange={this.handleChange}
          >
            <option value="all">All</option>
            {entryFeeOptions.map((e, i) => (
              <option key={i} value={e}>{`${e} gems`}</option>
            ))}
          </Form.Select>
          <Form.Select
            label="Duration"
            name="duration"
            onChange={this.handleChange}
          >
            <option value="all">All</option>
            <option value="120">0-2 hours</option>
            <option value="240">2-4 hours</option>
            <option value="360">4-6 hours</option>
            <option value="720">6-12 hours</option>
            <option value="1440">12+ hours</option>
          </Form.Select>
          <Form.Select
            label="Money Distribution"
            name="distribution"
            onChange={this.handleChange}
          >
            <option value="all">All</option>
            {this.state.distributions.map((m, i) => (
              <option value={m} key={i}>
                {m.charAt(0) +
                  m
                    .split("_")
                    .join(" ")
                    .toLowerCase()
                    .slice(1)}
              </option>
            ))}
          </Form.Select>
          {/* <span className="form-group">
            <label>Start Date</label>
            <Input
              value={this.state.dateCreated}
              readOnly={false}
              options={false}
              format="YYYY-MM-DD"
              onChange={this.handleDate}
            />
          </span> */}

          <Form.Select
            label="Created By"
            name="createdBy"
            onChange={this.handleChange}
          >
            <option value="all">All</option>
            {this.state.createdByList.map((m, i) => (
              <option value={m} key={i}>
                {m}
              </option>
            ))}
          </Form.Select>
          <Form.Select
            label="Sort By"
            name="sortBy"
            onChange={this.handleChange}
          >
            <option value="all">All</option>
            <option value="entryFee">Entry Fee</option>
            <option value="duration">Duration</option>
            <option value="distribution">Money Distribution</option>
            <option value="createdBy">Created By</option>
          </Form.Select>
        </div>
        <hr />
        <div className="table-schedule">
          <BlockRows
            data={this.state.currentBlocks}
            edit={this.edit}
            delete={this.props.deleteBlocks}
          />
        </div>
        {this.state.blocks.length && (
          <Pagination
            totalRecords={this.state.blocks.length}
            pageLimit={15}
            pageNeighbours={0}
            onPageChanged={this.onPageChanged}
          />
        )}
      </Home>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    blockData: state.blockState.blocks,
    entryFeeOptions: state.blockState.EntryFee,
    router: state.router
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      requestBlocks,
      deleteBlocks,
      requestCreateBlockOptions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockList);
