import * as React from "react";
import { Formik } from "formik";
import { Header as H, Form, Button } from "tabler-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as fetch from "isomorphic-fetch";
import Yup from "../../constants/validation";
// import { fetch } from "../../rpc/fetch";
import { TwirpError } from "../../rpc/service/twirp";
import { AuthConsumer } from "../../Contexts/AuthContext";
import Home from "../../components/home";
import PercentDialog from "./PercentDialog";
// import CustomDialog from "./customisedDialog";
import { requestCreateBlockOptions,requestWdc } from "../../actions/formActions";
import {
  WinningsDistributionFunction,
  BlockService
} from "../../rpc/service/pb";

interface Props {
  requestCreateBlockOptions: any;
  requestWdc: any;
  blockData: any;
  router: any;
  user: any;
  history: any;
}

const BlockSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  number: Yup.number().required("Required"),
  t1t2_duration_min: Yup.number().required("Required"),
  t2t3_duration_min: Yup.number().required("Required"),
  t3t4_duration_min: Yup.number().required("Required"),
  percentage_winners: Yup.number().required("Required")
});
class CreateBlock extends React.Component<Props> {
  state = {
    open: false,
    distributions: [],
    error: null,
    initValues: {
      name: "",
      entry_fee_value: "5",
      t3t4_duration_min: "",
      wdc_id:'',
      percentage_winners: Number("50"),
      t1t2_duration_min: "",
      t2t3_duration_min: "",
      number: ""
    }
  };

  private handleSubmit(values, token) {
    const block = new BlockService(process.env.REACT_APP_FETCH_URL, fetch);
    values = {
      ...values,
      created_by: this.props.user.displayName,
      entry_fee_currency: "INR"
    };
    block
      .createBlock(values, { authorization: `bearer ${token}` })
      .then(() => {
        this.props.history.push("/blocks/list");
      })
      .catch((err: TwirpError) => {
        console.error(err.code + ": " + err.message);
        this.setState({ error: err.message });
      });
  }

  componentDidMount = async () => {
    this.props.requestCreateBlockOptions();
    this.props.requestWdc();
    this.setState(
      { distributions: Object.values(WinningsDistributionFunction) }
      // ,() => console.log(this.state.distributions)
    );
    if (this.props.router.location.pathname == "/block/edit") {
      // console.log("i edit");
      let initValues = this.state.initValues;
      await this.setState(
        {
          initValues: {
            ...initValues,
            name:
              this.props.router.location.state &&
              this.props.router.location.state.Name,
            entry_fee_value:
              this.props.router.location.state &&
              this.props.router.location.state.EntryFeeValue,
            t3t4_duration_min:
              this.props.router.location.state &&
              Number(this.props.router.location.state.T3T4DurationMin),
            winnings_distribution_function:
              this.props.router.location.state &&
              this.props.router.location.state.Wdf,
              wdc_id:null,
            percentage_winners:
              this.props.router.location.state &&
              this.props.router.location.state.PercentageWinners,
            t1t2_duration_min:
              this.props.router.location.state &&
              Number(this.props.router.location.state.T3T4DurationMin),
            t2t3_duration_min:
              this.props.router.location.state &&
              Number(this.props.router.location.state.T3T4DurationMin),
            number:
              this.props.router.location.state &&
              this.props.router.location.state.Number
          }
        },
        () => console.log("check vl", this.state.initValues)
      );
    }
  };

  openModal = () => {
    this.setState({ open: true });
  };
  handleClose = () => this.setState({ open: false });
  render() {
    const { blockData } = this.props;
    const { initValues, distributions } = this.state;
    return (
      <AuthConsumer>
        {({ idToken }: any) => (
          <div className="block-form">
            <Home>
              <H.H1>Create a Block</H.H1>
              <hr />
              <Formik
                enableReinitialize
                initialValues={initValues}
                validationSchema={BlockSchema}
                onSubmit={(values, actions) => {
                  this.handleSubmit(values, idToken);
                  setTimeout(() => {
                    // alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                    actions.resetForm();
                  }, 1000);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  resetForm,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Input
                      name="name"
                      label="Name of the Block"
                      placeholder="Name of the Block"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name ? (
                      <div className="error">{errors.name}</div>
                    ) : null}
                    <Form.Input
                      name="number"
                      label="Number of the Block"
                      placeholder="Number of the Block"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.number}
                    />
                    {errors.number && touched.number ? (
                      <div className="error">{errors.number}</div>
                    ) : null}
                    <Form.Select
                      name="entry_fee_value"
                      label="Entry Fee"
                      placeholder="Entry Fee"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.entry_fee_value}
                    >
                      {blockData &&
                        blockData.EntryFee &&
                        blockData.EntryFee.map((e, i) => (
                          <option key={i} value={e}>{`${e} gems`}</option>
                        ))}
                    </Form.Select>

                    {/* <Form.Select
                      name="t1t2_duration_min"
                      label="Time Between T1-T2(in minutes)"
                      placeholder="Time Between T1-T2(in minutes)"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.t1t2_duration_min}
                    >
                      {blockData &&
                        blockData.duration &&
                        blockData.duration.map((e, i) => (
                          <option key={i} value={e}>{`${e} minutes`}</option>
                        ))}
                    </Form.Select> */}
                    <Form.Input
                      name="t1t2_duration_min"
                      label="Time Between T1-T2(in minutes)"
                      placeholder="Time Between T1-T2(in minutes)"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.t1t2_duration_min}
                    />
                    {errors.t1t2_duration_min && touched.t1t2_duration_min ? (
                      <div className="error">{errors.t1t2_duration_min}</div>
                    ) : null}
                    <Form.Input
                      name="t2t3_duration_min"
                      label="Time Between T2-T3(in minutes)"
                      placeholder="Time Between T2-T3(in minutes)"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.t2t3_duration_min}
                    />
                    {errors.t2t3_duration_min && touched.t2t3_duration_min ? (
                      <div className="error">{errors.t2t3_duration_min}</div>
                    ) : null}
                    <Form.Input
                      name="t3t4_duration_min"
                      label="Time Between T3-T4(in minutes)"
                      placeholder="Time Between T3-T4(in minutes)"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.t3t4_duration_min}
                    />
                    {errors.t3t4_duration_min && touched.t3t4_duration_min ? (
                      <div className="error">{errors.t3t4_duration_min}</div>
                    ) : null}
                    <Form.Select
                      name="wdc_id"
                      label="Winning Distribution"
                      placeholder="winning Distribution"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.wdc_id}
                    >
                      <option value="">Select</option>
                      {blockData.wdc.map((m, i) => (
                        
                        <option value={m.id} key={i}>
                          {m.Name}
                        </option>
                      ))}
                    </Form.Select>
                    {/* <Form.Select
                      name="winnings_distribution_function"
                      label="Money Distribution"
                      placeholder="Money Distribution"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.winnings_distribution_function}
                    >
                      {distributions.map((m, i) => (
                        <option value={m} key={i}>
                          {m.charAt(0) +
                            m
                              .split("_")
                              .join(" ")
                              .toLowerCase()
                              .slice(1)}
                        </option>
                      ))}
                    </Form.Select> */}
                    {values.winnings_distribution_function ==
                      WinningsDistributionFunction.PERCENTAGE_WINNERS_EXPONENTIAL_DISTRIBUTION && (
                      <>
                        {/* <Form.Select
                          name="percentage_winners"
                          label="Select %"
                          placeholder="Select %"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.percentage_winners}
                        >
                          {blockData &&
                            blockData.PercentageWinners &&
                            blockData.PercentageWinners.map((e, i) => (
                              <option key={i} value={e}>{`${e}%`}</option>
                            ))}
                        </Form.Select> */}
                        {/* <a href="#" onClick={this.openModal}>
                          View Details
                        </a> */}
                        <PercentDialog
                          isOpen={this.state.open}
                          handleClose={this.handleClose}
                        />
                      </>
                    )}
                    {/* {values.moneyDistribution == "custom" && (
                  <>
                    <a href="#" onClick={this.openModal}>
                      View Details
                    </a>
                    <CustomDialog
                      isOpen={this.state.open}
                      handleClose={this.handleClose}
                    />
                  </>
                )} */}
                    {/* {values.moneyDistribution == "lucky" && (
                  <>
                    <a href="#" onClick={this.openModal}>
                      View Details
                    </a>
                    <PercentDialog
                      isOpen={this.state.open}
                      handleClose={this.handleClose}
                      lucky={true}
                    />
                  </>
                )} */}
                    {values.winnings_distribution_function ==
                      WinningsDistributionFunction.PERCENTAGE_WINNERS_FLAT_DISTRIBUTION && (
                      <Form.Input
                        name="percentage_winners"
                        label="write %"
                        placeholder="write % here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.percentage_winners}
                      />
                    )}

                    <br />
                    <Button type="cancel" disabled={isSubmitting}>
                      Cancel
                    </Button>
                    <Button
                      className="submit"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Create
                    </Button>
                    <p className="twirp-error">{this.state.error}</p>
                  </Form>
                )}
              </Formik>
            </Home>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    blockData: state.blockState,
    router: state.router,
    user: state.loginState.user
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      requestCreateBlockOptions,
      requestWdc
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateBlock)
);
