import { put, takeEvery, call, fork } from "redux-saga/effects";
import { rsf } from "../firestore";
import * as types from "../constants/ActionTypes";
import * as firebase from "firebase/app";
import "@firebase/firestore";
import moment from "moment";

export function* getBlocks() {
  const snapshot = yield call(
    rsf.firestore.getCollection,
    "houses/mega/blocks"
  );
  let list;
  snapshot.forEach(function(e) {
    list = {
      ...list,
      [e.id]: {
        ...e.data(),
        id: e.id
      }
    };
  });
  yield put({ type: types.GET_BLOCKS, list: Object.values(list) });
}

export function* getGames() {
  const snapshot = yield call(rsf.firestore.getCollection, "games");
  let games;
  snapshot.forEach(function(e) {
    games = {
      ...games,
      [e.id]: {
        ...e.data(),
        id: e.id
      }
    };
  });
  yield put({ type: types.GET_GAMES, games: Object.values(games) });
}

export function* getTags() {
  const snapshot = yield call(rsf.firestore.getCollection, "tags");
  let tags;
  snapshot.forEach(function(e) {
    tags = {
      ...tags,
      [e.id]: {
        ...e.data(),
        id: e.id
      }
    };
  });
  yield put({ type: types.GET_TAGS, tags: Object.values(tags) });
}

export function* getWdc() {
  const snapshot = yield call(rsf.firestore.getCollection, "wdc");
  let wdc;
  snapshot.forEach(function(e) {
    wdc = {
      ...wdc,
      [e.id]: {
        ...e.data(),
        id: e.id
      }
    };
  });
  yield put({ type: types.GET_WDC, wdc: Object.values(wdc) });
}

export function* getSchedule() {
  const snapshot = yield call(
    rsf.firestore.getCollection,
    "houses/mega/schedules"
  );
  let scheduleList;
  snapshot.forEach(function(e) {
    scheduleList = {
      ...scheduleList,
      [e.id]: {
        ...e.data(),
        id: e.id
      }
    };
  });
  yield put({
    type: types.GET_SCHEDULE,
    scheduleList: Object.values(scheduleList)
  });
}

export function* getTemplates() {
  // let dateFrom = moment()
  //   .subtract(10, "d")
  //   .format("YYYY-MM-DD");

  const query = firebase
    .firestore()
    .collection("houses/mega/templates")
    .where("State", "==", "Active");
  const queryPaused = firebase
    .firestore()
    .collection("houses/mega/templates")
    .where("State", "==", "Paused");
  const queryCompleted = firebase
    .firestore()
    .collection("houses/mega/templates")
    .where("State", "==", "Completed");
  // .where("CreatedAt", ">", new Date(dateFrom));

  const snapshot = yield call(rsf.firestore.getCollection, query);
  const snapshotPaused = yield call(rsf.firestore.getCollection, queryPaused);
  const snapshotCompleted = yield call(
    rsf.firestore.getCollection,
    queryCompleted
  );
  let temp;
  snapshot.forEach(function(e) {
    temp = {
      ...temp,
      [e.id]: {
        ...e.data(),
        id: e.id
      }
    };
  });
  let tempPaused;
  snapshotPaused.forEach(function(e) {
    tempPaused = {
      ...tempPaused,
      [e.id]: {
        ...e.data(),
        id: e.id
      }
    };
  });
  let tempCompleted;
  snapshotCompleted.forEach(function(e) {
    tempCompleted = {
      ...tempCompleted,
      [e.id]: {
        ...e.data(),
        id: e.id
      }
    };
  });

  // console.log(temp);

  yield put({
    type: types.GET_TEMPLATES,
    templates: Object.values(temp),
    pausedTemplates: tempPaused && Object.values(tempPaused),
    completedTemplates: tempCompleted && Object.values(tempCompleted)
  });
}

export function* getRooms() {
  // console.log(moment("5/28/2019", "M/D/YYYY").valueOf());
  let dateFrom = moment()
    .subtract(6, "d")
    .format("YYYY-MM-DD");
  // console.log(dateFrom);
  const query = firebase
    .firestore()
    .collection("houses/mega/rooms")
    .where("T1Timestamp", ">", new Date(dateFrom));

  const snapshot = yield call(rsf.firestore.getCollection, query);
  let rooms;

  snapshot.forEach(function(e) {
    rooms = {
      ...rooms,
      [e.id]: {
        ...e.data(),
        id: e.id
      }
    };
  });
  // console.log(rooms, "dfadsadfa");

  // const snapshot = yield call(rsf.firestore.syncCollection as any, query);

  yield put({
    type: types.GET_ROOMS,
    rooms: Object.values(rooms)
  });
}

export function* getIntervals(data) {
  const query = firebase
    .firestore()
    .collection("houses/mega/templates/" + data.id + "/templatePauseIntervals")
    .where("State", ">", 1);

  const snapshot = yield call(rsf.firestore.getCollection, query);

  // const snapshot = yield call(
  //   rsf.firestore.getCollection,
  //   "houses/mega/templates/" + data.id + "/templatePauseIntervals"
  // );
  let temp;
  snapshot.forEach(function(e) {
    temp = {
      ...temp,
      [e.id]: {
        ...e.data(),
        id: e.id
      }
    };
  });

  yield put({
    type: types.GET_PAUSE_INTERVALS,
    pauseIntervals: temp ? Object.values(temp) : null
  });
}

export default function* sets() {
  yield takeEvery(types.REQUEST_TAGS, getTags),
  yield takeEvery(types.REQUEST_WDC, getWdc),
    yield takeEvery(types.REQUEST_BLOCKS, getBlocks),
    yield takeEvery(types.REQUEST_GAMES, getGames),
    yield takeEvery(types.REQUEST_SCHEDULE, getSchedule),
    yield takeEvery(types.REQUEST_ROOMS, getRooms);
  yield takeEvery(types.REQUEST_TEMPLATES, getTemplates);
  yield takeEvery(types.REQUEST_PAUSE_INTERVALS, getIntervals);
}
