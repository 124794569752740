import * as React from "react";
import { Header as H, Icon, Form } from "tabler-react";
import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Home from "../../components/home";
import TemplateRows from "./templateRows";
import Create from "../../assets/fab.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestSchedule } from "../../actions/formActions";
import { deleteBlocks } from "../../actions/deleteActions";
import Pagination from "../../components/pagination";
import { days } from "../Scheduler/schedulerList";
import { slots } from "./createSchedule";

interface Props {
  requestSchedule: any;
  history: any;
  scheduleData: any;
}

class TemplateList extends React.Component<Props> {
  state = {
    dateCreated: null,
    schedules: [],
    createdByList: [],
    Filters: {
      repeat: "all",
      timeslots: "all",
      date: "all",
      createdBy: "all"
    },
    newTemp: [],
    currentSchedules: []
  };
  fetchData = async () => {
    await this.props.requestSchedule();
  };

  handleSelect = (data, name) => {
    // console.log(data, name, "data name");
    this.setState(
      {
        Filters: { ...this.state.Filters, [name]: data }
      },
      () => {
        // console.log(this.state.Filters);
        this.applyFilter(this.state.Filters);
      }
    );
  };

  componentDidMount = async () => {
    await this.fetchData();
  };

  onPageChanged = async data => {
    // console.log(data, "-----data-------");
    const { schedules } = await this.state;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentSchedules = schedules.slice(offset, offset + pageLimit);

    this.setState(
      { currentPage, currentSchedules, totalPages }
      // , () =>
      // console.log(this.state.currentBlocks, "curntblock")
    );
  };

  handleChange = event => {
    let Filters = { ...this.state.Filters };
    let name = event.target.name;
    // console.log(name, event.target.value);
    Filters[name] = event.target.value;
    this.setState({ ...this.state.Filters, Filters }, () =>
      this.applyFilter(this.state.Filters)
    );
  };

  daysFilter = async (g, newTemp) => {
    // console.log(newTemp, "days  in dfilter", g);
    if (Array.isArray(g)) {
      let temp = g.map(g =>
        g.value !== "all" && g.value !== undefined
          ? newTemp.filter(a => a.Days.indexOf(g.value) > -1)
          : newTemp
      );
      // console.log(temp, "temp in dfilter");
      let i, temp1;
      temp1 = g.length && [...temp[0]];
      if (g.length > 1) {
        for (i = 1; i < g.length; i++) {
          // console.log([...temp[i], ...temp[0]], "timesl");
          temp1 = await [...temp[i], ...temp1];
        }
      }

      temp1 = g.length ? [...new Set(temp1)] : newTemp;
      // console.log(temp1, "temp1 in dayfilter", [...new Set(temp1)]);
      await this.setSchedule(temp1);
      return temp1;
    } else {
      return newTemp;
    }
  };

  setSchedule = temp => {
    this.setState(
      {
        schedules: temp
      },
      () => {
        let data = {
          currentPage: 1,
          pageLimit: 15,
          totalPages: Math.ceil(this.state.schedules.length / 15),
          totalRecords: this.state.schedules.length
        };
        this.onPageChanged(data);

        // console.log(this.state.templates);
      }
    );
  };

  timeslotFilter = async (g, newTemp) => {
    // console.log(Array.isArray(g), "timeslots  in tsfilter", g);
    if (Array.isArray(g)) {
      let temp = g.map(g =>
        g.value !== "all" && g.value !== undefined
          ? newTemp.filter(a => a.SpawnWindows.indexOf(g.value) > -1)
          : newTemp
      );
      let i, temp1;
      temp1 = g.length && [...temp[0]];
      if (g.length > 1) {
        for (i = 1; i < g.length; i++) {
          // console.log([...temp[i], ...temp[0]], "timesl");
          temp1 = await [...temp[i], ...temp1];
        }
      }
      temp1 = g.length ? [...new Set(temp1)] : newTemp;
      // console.log("temp in timeslot", temp);
      await this.setSchedule(temp1);
      return temp1;
    } else {
      return newTemp;
    }
  };

  dateFilter = (f, newTemp) => {
    // console.log(f, "ffff");
    let temp =
      f !== "all" && f !== undefined
        ? newTemp.filter(e => {
            // if (moment.unix(e.CreatedAt.seconds).format("ddd,LL") == f)
            // console.log(moment.unix(e.CreatedAt.seconds).format("ddd,LL"));
            return (
              moment(new Date(e.CreatedAt.seconds * 1000)).format("ddd,LL") ==
              moment(f).format("ddd,LL")
            );
          })
        : newTemp;
    // console.log("temp in game", temp);
    this.setSchedule(temp);
    return temp;
  };

  createdByFilter = (f, newTemp) => {
    let temp =
      f !== "all" && f !== undefined
        ? newTemp.filter(e => {
            return e.CreatedBy == f;
          })
        : newTemp;
    this.setSchedule(temp);
    return temp;
  };

  applyFilter = async f => {
    const { newTemp } = this.state;

    let a = newTemp;
    let b = await this.daysFilter(f.repeat, a);

    let c = await this.timeslotFilter(f.timeslots, b);

    let d = await this.createdByFilter(f.createdBy, c);
    await this.dateFilter(f.date, d);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.scheduleData != this.props.scheduleData) {
      this.setState(
        { schedules: this.props.scheduleData, newTemp: this.props.scheduleData }
        // , () =>
        // console.log(this.state.schedules, "schedules in did update")
      );
      let createdByList = this.props.scheduleData.map(f => f.CreatedBy);
      // console.log([...new Set(createdByList)]);
      createdByList = [...new Set(createdByList)];
      this.setState({ createdByList });
    }
  };
  handleVariable = e => {
    let a = e.target.name;
    this.setState({ [a]: e.target.value }, () => console.log(this.state));
  };
  handleDate = event => {
    // console.log(event);
    this.setState(
      {
        dateCreated: event,
        Filters: {
          ...this.state.Filters,

          date: event
        }
      },
      () => {
        // console.log(this.state.Filters);
        this.applyFilter(this.state.Filters);
      }
    );
  };
  render() {
    return (
      <Home>
        <div className="create">
          <H.H3>Schedule</H.H3>
          <img
            src={Create}
            alt="create new"
            onClick={() => this.props.history.push("/schedule/create")}
          />
        </div>
        <div className="filters">
          <div className="repeatSchedule">
            <label>Repeat</label>
            <Select
              placeholder="All"
              defaultValue="all"
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              isSearchable={false}
              isMulti
              value={this.state.Filters.repeat}
              onChange={e => this.handleSelect(e, "repeat")}
              options={days}
              name="repeat"
            />
          </div>
          <div>
            <label>Timeslots</label>
            <Select
              placeholder="All"
              defaultValue="all"
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              isSearchable={false}
              isMulti
              // menuIsOpen
              value={this.state.Filters.timeslots}
              onChange={e => this.handleSelect(e, "timeslots")}
              options={slots}
              name="timeslots"
            />
          </div>
          <span className="form-group">
            <label>Date Created</label>
            <DatePicker
              placeholderText="Select Date"
              selected={this.state.dateCreated}
              timeInputLabel="Time:"
              onChange={this.handleDate}
              dateFormat="MM/dd/yyyy"
            />
            {/* <Input
              value={this.state.dateCreated}
              readOnly={false}
              options={false}
              format="YYYY-MM-DD"
              onChange={this.handleDate}
            /> */}
          </span>

          <Form.Select
            label="Created By"
            name="createdBy"
            onChange={this.handleChange}
          >
            <option value="all">All</option>
            {this.state.createdByList.map((m, i) => (
              <option value={m} key={i}>
                {m}
              </option>
            ))}
          </Form.Select>
        </div>
        <hr />
        <div className="table-schedule">
          <TemplateRows data={this.state.currentSchedules} />
        </div>
        {this.state.schedules.length && (
          <Pagination
            totalRecords={this.state.schedules.length}
            pageLimit={15}
            pageNeighbours={0}
            onPageChanged={this.onPageChanged}
          />
        )}
      </Home>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    scheduleData: state.blockState.scheduleList,
    router: state.router
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      requestSchedule
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateList);
