import * as React from "react";
import { Dialog } from "@blueprintjs/core";
import { Form, Table, Button } from "tabler-react";

interface DialogState {
  autoFocus: boolean;
  canEscapeKeyClose: boolean;
  canOutsideClickClose: boolean;
  enforceFocus: boolean;
  isOpen: boolean;
  usePortal: boolean;
  lazy: boolean;
  Percent: Object;
}

interface DialogProps {
  isOpen: boolean;
  handleClose(): void;
  lucky?: boolean;
}

class PercentDialog extends React.Component<DialogProps, DialogState> {
  public state: DialogState = {
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    isOpen: false,
    usePortal: true,
    lazy: true,

    Percent: {
      winner: "50",
      size: 2000
    }
  };

  handleChange = event => {
    let Percent = {};
    Percent[event.target.name] = event.target.value;
    this.setState({ Percent });
  };

  render() {
    return (
      <>
        <Dialog
          className="percent-dialog"
          icon="info-sign"
          onClose={this.props.handleClose}
          title=""
          {...this.state}
          isOpen={this.props.isOpen}
        >
          <div>
            <div className="input-boxes">
              <Form.Select
                label="Money Distribution"
                name="winner"
                value={this.state.Percent["winner"]}
                onChange={this.handleChange}
              >
                <option value="50">50 % Winner</option>
                <option value="10">10 % Winner</option>
              </Form.Select>
              {this.props.lucky && (
                <Form.Select
                  label="Skip Rank"
                  name="lucky"
                  value={this.state.Percent["lucky"]}
                  onChange={this.handleChange}
                >
                  <option value="1">1</option>
                  <option value="5">5</option>
                </Form.Select>
              )}
              <Form.Input
                label="Room Size"
                name="size"
                value={this.state.Percent["size"]}
                onChange={this.handleChange}
              />
            </div>
            <div className="table">
              <Table>
                <Table.Header>
                  <Table.ColHeader>Rank Bucket</Table.ColHeader>
                  <Table.ColHeader>Money</Table.ColHeader>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Col>1-4</Table.Col>
                    <Table.Col>50</Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>1-4</Table.Col>
                    <Table.Col>50</Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>1-4</Table.Col>
                    <Table.Col>50</Table.Col>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
            <div className="buttons">
              <Button type="cancel">Cancel</Button>
              <Button type="submit">Create</Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

export default PercentDialog;
