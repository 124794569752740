import {
  delay,
  put,
  takeEvery,
  all,
  call,
  take,
  fork
} from "redux-saga/effects";
import { rsf } from "../firestore";
import * as types from "../constants/ActionTypes";
import loginRootSaga from "./login";
import blockSaga from "./blocks";
import setSaga from "./set";
import deleteSaga from "./delete";

// export function* getCollection() {
//   const task1 = yield fork(fetchUsers, "users");
//   yield delay(1000);
// }

// export function* fetchUsers(resource: any) {
//   const snapshot = yield call(rsf.firestore.getCollection, resource);
//   let users: any;
//   snapshot.forEach((user: any) => {
//     users = {
//       ...users,
//       [user.id]: user.data()
//     };
//   });
//   yield put({ type: types.GET_USERS, users: Object.values(users) });
// }

// export function* fetchUsersAsync() {
//   yield call(getCollection);
// }

export default function* rootSaga() {
  yield all([loginRootSaga(), blockSaga(), setSaga(), deleteSaga()]);
}
