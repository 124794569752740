import * as React from "react";
import { Dialog } from "@blueprintjs/core";
import { Table } from "tabler-react";
import moment from "moment";

interface DialogState {
  autoFocus: boolean;
  canEscapeKeyClose: boolean;
  canOutsideClickClose: boolean;
  enforceFocus: boolean;
  isOpen: boolean;
  usePortal: boolean;
}

interface DialogProps {
  isOpen: boolean;
  handleClose(): void;
  data: any;
}

class ScheduleDialog extends React.Component<DialogProps, DialogState> {
  public state: DialogState = {
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    isOpen: false,
    usePortal: true
  };
  render() {
    const { data } = this.props;
    // console.log(data, "data");
    return (
      <Dialog
        className="schedule-dialog"
        icon="info-sign"
        onClose={this.props.handleClose}
        title=""
        {...this.state}
        isOpen={this.props.isOpen}
      >
        <Table>
          <Table.Header>
            <Table.ColHeader>Room Name</Table.ColHeader>
            <Table.ColHeader>Date</Table.ColHeader>
            <Table.ColHeader>TimeSlot</Table.ColHeader>
            <Table.ColHeader>Attempts PP</Table.ColHeader>
            <Table.ColHeader>Play time Ratio</Table.ColHeader>
            <Table.ColHeader>Total Gems Collected</Table.ColHeader>
          </Table.Header>
          <Table.Body>
            {data.map((o, i) => (
              <>
                {!o.RoomStateOpenToClosed &&
                  (o.State == "Result Declared" || o.State == "Cancelled") && (
                    <Table.Row key={i}>
                      <Table.Col>{o.Name}</Table.Col>
                      <Table.Col>
                        {" "}
                        {moment
                          .unix(o.T1Timestamp && o.T1Timestamp.seconds)
                          .format("ddd,LL")}
                      </Table.Col>
                      <Table.Col>
                        {o.Template.Schedule.SpawnWindows.map(
                          m =>
                            m.charAt(0) +
                            m
                              .split("_")
                              .join(" ")
                              .toLowerCase()
                              .slice(1) +
                            ","
                        )}
                      </Table.Col>
                      <Table.Col>-</Table.Col>
                      <Table.Col>-</Table.Col>
                      <Table.Col>{o.EntryFeeCollected}</Table.Col>
                    </Table.Row>
                  )}
              </>
            ))}
            {data.map((o, i) => (
              <>
                {o.RoomStateOpenToClosed && (
                  <Table.Row className="current" key={i}>
                    <Table.Col>{o.Name}</Table.Col>
                    <Table.Col>
                      {moment
                        .unix(o.T1Timestamp && o.T1Timestamp.seconds)
                        .format("ddd,LL")}
                    </Table.Col>
                    <Table.Col>
                      {o.Template.Schedule.SpawnWindows.map(
                        m =>
                          m.charAt(0) +
                          m
                            .split("_")
                            .join(" ")
                            .toLowerCase()
                            .slice(1) +
                          ","
                      )}
                    </Table.Col>
                    <Table.Col>-</Table.Col>
                    <Table.Col>-</Table.Col>
                    <Table.Col>{o.EntryFeeCollected}</Table.Col>
                  </Table.Row>
                )}
              </>
            ))}
            {data.map((o, i) => (
              <>
                {/* <Table.Row
                  className={
                    !o.RoomStateOpenToClosed && o.State == "Draft"
                      ? "toBeDone"
                      : !o.RoomStateOpenToClosed &&
                        (o.State == "Result Declared" || o.State == "Cancelled")
                      ? null
                      : "current"
                  }
                  key={i}
                >
                  <Table.Col>{o.Name}</Table.Col>
                  <Table.Col>{o.date}</Table.Col>
                  <Table.Col>{o.timeslot}</Table.Col>
                  <Table.Col>-</Table.Col>
                  <Table.Col>-</Table.Col>
                  <Table.Col>{o.EntryFeeCollected}</Table.Col>
                </Table.Row> */}
                {!o.RoomStateOpenToClosed && o.State == "Draft" && (
                  <Table.Row className="toBeDone" key={i}>
                    <Table.Col>{o.Name}</Table.Col>
                    <Table.Col>
                      {" "}
                      {moment
                        .unix(o.T1Timestamp && o.T1Timestamp.seconds)
                        .format("ddd,LL")}
                    </Table.Col>
                    <Table.Col>
                      {o.Template.Schedule.SpawnWindows.map(
                        m =>
                          m.charAt(0) +
                          m
                            .split("_")
                            .join(" ")
                            .toLowerCase()
                            .slice(1) +
                          ","
                      )}
                    </Table.Col>
                    <Table.Col>-</Table.Col>
                    <Table.Col>-</Table.Col>
                    <Table.Col>{o.EntryFeeCollected}</Table.Col>
                  </Table.Row>
                )}
              </>
            ))}
          </Table.Body>
        </Table>
      </Dialog>
    );
  }
}

export default ScheduleDialog;
