export const GET_USERS = "GET_USERS";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const SYNC_USER = "SYNC_USER";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const CHECK_USER = "CHECK_USER";
export const REQUEST_CREATE_BLOCK_OPTIONS = "REQUEST_CREATE_BLOCK_OPTIONS";
export const GET_CREATE_BLOCK_OPTIONS = "GET_CREATE_BLOCK_OPTIONS";
export const REQUEST_BLOCKS = "REQUEST_BLOCKS";
export const GET_BLOCKS = "GET_BLOCKS";
export const REQUEST_GAMES = "REQUEST_GAMES";
export const GET_GAMES = "GET_GAMES";
export const REQUEST_SCHEDULE = "REQUEST_SCHEDULE";
export const GET_SCHEDULE = "GET_SCHEDULE";
export const REQUEST_ROOMS = "REQUEST_ROOMS";
export const REQUEST_TEMPLATES = "REQUEST_TEMPLATES";
export const GET_ROOMS = "GET_ROOMS";
export const GET_TEMPLATES = "GET_TEMPLATES";
export const DELETE_BLOCKS = "DELETE_BLOCKS";
export const DELETE_ROOM = "DELETE_ROOM";
export const REQUEST_PAUSE_INTERVALS = "REQUEST_PAUSE_INTERVALS";
export const GET_PAUSE_INTERVALS = "GET_PAUSE_INTERVALS";
export const REQUEST_TAGS = "REQUEST_TAGS";
export const GET_TAGS = "GET_TAGS";
export const REQUEST_WDC = "REQUEST_WDC";
export const GET_WDC = "GET_WDC";