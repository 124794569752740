import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loginRequest, logoutRequest } from "../actions/loginActions";

interface AuthInterface {
  isAuth: boolean;
  login(): void;
  logout(): void;
}

interface Props {
  loginState: any;
  loginRequest: any;
  logoutRequest: any;
  user: any;
}

const AuthContext = React.createContext<AuthInterface | null>(null);

class AuthProvider extends React.Component<Props> {
  state = { isAuth: this.props.loginState.isAuth };

  login = () => {
    this.props.loginRequest();
  };

  logout = () => {
    this.props.logoutRequest();
  };

  public render() {
    // console.log(this.props.user && this.props.user.displayName);
    return (
      <AuthContext.Provider
        value={{
          user: this.props.user && this.props.user.displayName,
          idToken: this.props.loginState.idToken,
          isAuth: this.props.loginState.isAuth,
          login: this.login,
          logout: this.logout,
          redirectToReferrer: this.props.loginState.redirectToReferrer,
          loading: localStorage.getItem("loading")
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    loginState: state.loginState,
    user: state.loginState.user
  };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      loginRequest,
      logoutRequest
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthProvider);

export const AuthConsumer = AuthContext.Consumer;
